/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Content, Header, Page } from '@backstage/core-components';
import { SearchBar } from '@backstage/plugin-search-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { makeStyles, Paper, TableContainer, Theme } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import React, { useEffect, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useLargeScreenDimensions } from '../../../hooks/useWindowDimensions';
import { RadarComponent, TechRadarComponentProps } from './RadarComponent';
import RadarFilter from './RadarFilter';
import { TechRadarFilterProps } from './types';

import './drawer.css';

/**
 * Properties for {@link TechRadarPage}
 *
 * @public
 */
export interface TechRadarPageProps extends TechRadarComponentProps {
  /**
   * Title
   */
  title?: string;
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Page Title
   */
  pageTitle?: string;

  urlLocation?: string;
}

/**
 * Main Page of Tech Radar
 *
 * @public
 */
const useStyles = makeStyles((theme: Theme) => ({
  overflowXScroll: {
    overflowXScroll: 'hidden',
  },
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  container: {
    marginLeft: 12,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  searchContainer: {
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '1px',
    paddingBottom: '10px',
  },
  techRaderFilter: {
    width: '280px',
    height: '100px',
    flexGrow: 0,
    flexBasis: '200px',
  },

  Radartags: {
    backgroundColor: '#fff',
    height: '50px',
    padding: '15px',
    border: 'none',
    borderRadius: '5px',
    width: '60px',
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer', // Change the cursor to pointer on hover
    },
  },
}));

export function RadarPage(props: TechRadarPageProps) {
  const {
    title = 'Tech Radar',
    subtitle = 'Pick the recommended technologies for your projects',
  } = props;
  const classes = useStyles();

  const [radarState, setRadarState] = useState({
    techRadarlocation: '',
    entities: [],
    filterList: [],
    filteredResults: [] as TechRadarFilterProps[],
    selected: '',
    tagListList: [],
    isTagListLoaded: false,
    isSearchEmpty: false,
    searchParam: '',
    isNavOpen: false,
  });
  const [techRadarlocation, setTechRadarLocation] = React.useState('');
  const combinedEntities: any[] = [];
  let techRadarApiList: any[] = [];
  document.body.style.overflow = 'hidden';

  const { width, height } = useLargeScreenDimensions();

  useEffect(() => {
    const getTechRadarLocation = async () => {
      const location = (await fetchData(
        urls.getConfigByName.concat('techRadar.location'),
      )) as string;
      setTechRadarLocation(location);
    };
    getTechRadarLocation();
  }, []);

  useEffect(() => {
    const getJsonContentByLocation = async () => {
      if (techRadarlocation) {
        const getAllFilesInLocation = await fetchData(
          urls.getAdrList.concat(techRadarlocation ?? ''),
        );
        if (getAllFilesInLocation) {
          for (const data of getAllFilesInLocation.data) {
            const url = techRadarlocation.concat('/').concat(data?.path);
            const location = await fetchData(
              urls.getAdrContentByName.concat(url),
            );
            setRadarState(prev => ({
              ...prev,
              filterList: [
                ...prev.filterList,
                data?.name?.split('.')[0],
              ] as any,
              entities: [...prev.entities, JSON.parse(location?.data)] as any,
            }));
          }
        }
      }
    };
    getJsonContentByLocation();
  }, [techRadarlocation]);

  

  const onFilter = (tag: string) => {
    const entries = radarState.entities.flat() as any;
    const customRadarentries_ = entries.map((entry: any[])=>{
      return entry.entries
    });
    const listToFilter = customRadarentries_.flat();
    const { searchParam } = radarState;
    let filteredResults:TechRadarFilterProps[] = [];

    if (tag && !searchParam) {
      filteredResults = listToFilter.filter((x: { tags: string[] }) =>
        x?.tags?.includes(tag),
      ) 
    } else if (tag && searchParam) {
      filteredResults = listToFilter.filter(
        (x: { tags: string[]; title: string }) =>
          x?.tags?.includes(tag) && x.title.toLowerCase().includes(searchParam),
      );
    } else if (!tag && searchParam) {
      filteredResults = listToFilter.filter((x: { title: string }) =>
        x.title.toLowerCase().includes(searchParam),
      );
    } else if (!tag && !searchParam) {
      filteredResults = listToFilter;
    }
    if (filteredResults.length > 0) {
      setRadarState(prev => ({
        ...prev,
        filteredResults: filteredResults ,
        selected: tag,
        isSearchEmpty: false,
        isNavOpen: false,
      }));
    } else {
      setRadarState(prev => ({
        ...prev,
        filteredResults: [],
        selected: tag,
        isSearchEmpty: true,
        isNavOpen: false,
      }));
    }
  };

  const handleSearch = (str: string) => {
    const entries = radarState.entities.flat() as any;
    const customRadarentries_ = entries.map((entry: any[])=>{
      return entry.entries
    });
    const listToFilter = customRadarentries_.flat();
    const { selected } = radarState;

    let filteredResults:TechRadarFilterProps[] = [];
    if (str && !selected) {
      filteredResults = listToFilter.filter((x: { title: string }) =>
        x?.title.toLowerCase().includes(str.toLowerCase()),
      ) 
    } else if (str && selected) {
      filteredResults = listToFilter?.filter(
        (x: { title: string; tags: string[] }) =>
          x?.title.toLowerCase().includes(str.toLowerCase()) &&
          x?.tags?.includes(selected.toString()),
      ) 
    } else if (!str && selected) {
      filteredResults = listToFilter?.filter(
        (item: any) => Array.isArray(item.tags) && item.tags.includes(selected),
      ) 
    }

    if (filteredResults.length > 0 && str) {
      setRadarState(prev => ({
        ...prev,
        filteredResults: filteredResults,
        isSearchEmpty: false,
        searchParam: str,
      }));
    } else if (filteredResults.length > 0) {
      setRadarState(prev => ({
        ...prev,
        filteredResults: filteredResults,
        isSearchEmpty: false,
        searchParam: str,
      }));
    } else if (filteredResults.length < 1 && str) {
      setRadarState(prev => ({
        ...prev,
        filteredResults: filteredResults,
        isSearchEmpty: true,
        searchParam: str,
      }));
    } else if (filteredResults.length < 1 && !str) {
      setRadarState(prev => ({
        ...prev,
        filteredResults: filteredResults,
        isSearchEmpty: false,
        searchParam: str,
      }));
    }
  };

  const setTagList = (tagList: string[]) => {
    let list: string[] = [];
    if (!radarState.isTagListLoaded) {
      if (tagList && tagList.length > 0) {
        const combinedArray = tagList.reduce(
          (acc, curr) => acc.concat(curr as any),
          [],
        );
        const unique = new Set(combinedArray);
        list = Array.from(unique) as any;
      } else {
        list = Array.from(new Set(tagList)) as any;
      }
      setRadarState(prev => ({
        ...prev,
        tagListList: list as any,
        isTagListLoaded: true,
      }));
    }
  };

  const set_TechRadarApiList = (list: any) => {
    if (techRadarApiList?.length < 1) {
      techRadarApiList = list.entries;
    }
  };

  const handleNav = () => {
    const { isNavOpen } = radarState;
    setRadarState(prev => ({
      ...prev,
      isNavOpen: isNavOpen ? false : true,
    }));
  };

  return (
    <div id="techRadar">
      <Page themeId="tool">
        <Header title={title} subtitle={subtitle} />
        <Content className={classes.overflowXScroll}>
          <div className={classes.searchContainer}>
            <SearchBar
              placeholder="Search in Tech Radar"
              onChange={e => handleSearch(e)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%' }} role="button">
            <div className={classes.Radartags} onClick={handleNav}>
             <FilterListIcon/>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
                maxHeight: '100vh',
                width: '100%',
                marginTop: '-20px',
              }}
            >
              <Drawer
                anchor="left"
                open={radarState.isNavOpen}
                onClose={handleNav}
              >
                <div className={classes.techRaderFilter}>
                  <RadarFilter
                    filterList={radarState.tagListList}
                    onFilter={onFilter}
                    selected={radarState.selected}
                  />
                </div>
              </Drawer>
              <div
                style={{
                  flex: 1,
                  marginTop: '30px',
                  textAlign: 'center',
                  minHeight: 'auto',
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    padding: '3em',
                  }}
                >
                  <RadarComponent
                    height={height / 1.5}
                    width={width / 1.3}
                    entries={radarState.entities}
                    onFilter={onFilter}
                    filteredResults={radarState.filteredResults}
                    allEntities={combinedEntities}
                    setTechRadarApiResponse={set_TechRadarApiList}
                    getTagsList={setTagList}
                    isSearchEmpty={radarState.isSearchEmpty}
                  />
                </TableContainer>
              </div>
              
            </div>
          </div>
        </Content>
      </Page>
    </div>
  );
}
