import { EntityLayout, EntityLinksCard } from "@backstage/plugin-catalog";
import { EntityMembersListCard, EntityOwnershipCard } from "@backstage/plugin-org";
import { Grid } from "@material-ui/core";
import React from "react";

import { CustomEntityLayout } from "../../../pages/EntityLayout";
import { RolesListCard } from "../../../pages/RolesResponsibilities";

export const groupPage = (entityWarningContent: JSX.Element) => (
    <CustomEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item md={6} xs={12}>
            <EntityLinksCard variant="gridItem"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <RolesListCard title="Roles and responsibilities" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityMembersListCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </CustomEntityLayout>
  );
