import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback, useEffect, useState } from 'react';

/*
 This is the actual component that will get rendered in the form
*/
export const TrackSpaceUserExtension = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {

  const user=useApi(identityApiRef) as any;
  const [userId,setUserId]=useState('');

  const getTrackSpaceUser=useCallback(async()=>{
      const user_=await fetchData(`${urls.getTrackSpaceUser}${user.target.profile.email.split('@')[0]}`);
      setUserId(
        user_[0]?.name
      )
  },[])
  

  useEffect(()=>{
    getTrackSpaceUser()
  },[user.target.profile.displayName,getTrackSpaceUser])

  if(userId){
    onChange(userId)
  }
  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !formData}

      >
        Requester Name:   <Input
        onChange={e => onChange(e.target?.value)}
        readOnly
        value={userId}
      />
      </FormControl>
    </>
  );
}
  
  
