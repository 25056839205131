import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  ValidateAzureDevOpsExtension,
} from './AzureDevOpsValidator';

export const AzureDevOpsValidator: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'validateAzureDevOpsProject',
    component: ValidateAzureDevOpsExtension,
  }),
);
