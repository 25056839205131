import { fetchData } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useEffect, useState } from 'react';
import React from 'react';

import { GuideLineProps } from './types';

const GuideLines = () => {
  const [guideLineList, setGuideLinesList] = useState<GuideLineProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getGuideLinesById = async () => {
      try {
        const response = await fetchData(
          `api/catalog/entities/by-name/domain/default/cop_dso`,
        );
        const spec=response?.spec?.docs as GuideLineProps[]
        setGuideLinesList(spec);
        setIsLoading(false);
      } catch (error) {
        setGuideLinesList([]);
        setIsLoading(false);
      }
    };
    getGuideLinesById();
  }, []);

  return <div><Card data-testid="entity-group-list">
      <CardHeader title="GuideLines" />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {guideLineList.sort((a,b)=>{
              return a.title.localeCompare(b.title)
            }).length > 0 ? (
              <Table aria-label="group list" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guideLineList.map(item => (
                    <TableRow key={item.url}>
                      <TableCell>
                      <b><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></b>

                      </TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              'No data'
            )}
          </>
        )}
      </CardContent>
    </Card></div>
  

};

export default GuideLines;
