import { parseEntityRef } from '@backstage/catalog-model';
import { Content, Header, Page } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import { Card, CardContent, Grid, LinearProgress, makeStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import jsPDF from 'jspdf';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { getSystemsCountByTeams } from '../../services/systems.service';

import 'jspdf-autotable';

const useStyles = makeStyles(theme => ({
  fullWidthCard: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  fullWidthContainer: {
    width: '100%',
  },
  filterTextField: {
    marginBottom: '1em',
    height: '40px',
    float: 'left',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    outline: 'none',
  },
  table: {
    width: '100%',
  },
  tableCell: {
    wordBreak: 'break-word',
    width: '15em',
  },
  downloadIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    float: 'right',
  },
}));

interface systemsProps {
  count: {
    api: number;
    component: number;
    system: number;
    resource: number;
  };
  team: string;
  link: string;
  type: string;
}

const Analytics = () => {
  const classes = useStyles();
  const [systemsByTeamList, setSystemsByTeamList] = useState([]);
  const [systemsByTeamList_, setSystemsByTeamList_] = useState<systemsProps[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const catalogApi = useApi(catalogApiRef);

  const getSystems = useCallback(async () => {
    try {
      const data = await getSystemsCountByTeams();
      setIsLoading(false);
      setSystemsByTeamList(data);
      return data;
    } catch (error) {
      if (error instanceof Error) {
        setIsLoading(false);
      }
    }
    return null;
  }, []);

  useEffect(() => {
    getSystems();
  }, [getSystems]);

  useEffect(() => {
    async function getNameById() {
      if (systemsByTeamList.length > 0) {
        systemsByTeamList.map(async (item: systemsProps) => {
          const entityRef = await parseEntityRef(item.team);
          if (entityRef) {
            const value = (await catalogApi.getEntityByRef(entityRef)) as any;
            if (value?.spec) {
              setSystemsByTeamList_(list => [
                ...list,
                {
                  team: value?.spec?.profile?.displayName as string,
                  count: item.count,
                  link: item.team,
                  type: value?.spec?.type,
                },
              ]);
            }
          }
          return null;
        });
      }
    }
    getNameById();
  }, [systemsByTeamList, catalogApi]);

  const handlePageChange = (event: any, newPage: number) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const filteredSystems = systemsByTeamList_?.filter((item: systemsProps) =>
    item.team.toLowerCase().includes(filter.toLowerCase()),
  );

  const paginatedSystems = filteredSystems

    .filter(
      x =>
        x.type.toLowerCase() === 'product team' ||
        x.type.toLowerCase() === 'value stream' ||
        x.type.toLowerCase() === 'team',
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const onDownload = () => {
    const doc = new jsPDF(); // eslint-disable-line new-cap
    const tableColumn = ['Team', 'Type', 'Api Count','Product Count', 'Component Count','Resource Count'];
    const tableRows = systemsByTeamList_
      .filter(
        x =>
          x.type.toLowerCase() === 'product team' ||
          x.type.toLowerCase() === 'value stream' ||
          x.type.toLowerCase() === 'team',
      )
      .map(item => [item.team, item.type, item.count.api,item.count.system,item.count.component,item.count.resource]);
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    doc.text(`Dh Dock Systems Report on ${formattedDate}`, 14, 16);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save('Dh Dock Onboarded Products Report.pdf');
  };

  return (
    <Page themeId="tool">
      <Header title="Onboarding Report" />
      <Content>
        {isLoading && <LinearProgress />}

        {!isLoading && systemsByTeamList_.length>0 && (
          <Grid container className={classes.fullWidthContainer}>
            <Grid item xs={12}>
              <Card className={classes.fullWidthCard}>
                <CardContent>
                  {!isLoading &&
                    systemsByTeamList &&
                    systemsByTeamList_.length > 0 && (
                      <>
                        <button
                          className={classes.downloadIcon}
                          onClick={onDownload}
                        >
                          <CloudDownloadIcon />
                        </button>
                        <input
                          type="text"
                          placeholder="filter by team"
                          value={filter}
                          onChange={handleFilterChange}
                          className={classes.filterTextField}
                        />
                        <Table
                          aria-label="group list"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Team</TableCell>
                              <TableCell>Team Type</TableCell>
                              <TableCell>Api Count</TableCell>
                              <TableCell>Product Count</TableCell>
                              <TableCell>Component Count</TableCell>
                              <TableCell>Resource Count</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedSystems.length > 0 &&
                              !isLoading &&
                              paginatedSystems.map((item: systemsProps) => (
                                <TableRow key={item.team}>
                                  <TableCell className={classes.tableCell}>
                                    <EntityRefLink entityRef={item.link}>
                                      <b>{item.team}</b>
                                    </EntityRefLink>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {item.type}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {item.count.api}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {item.count.system}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {item.count.component}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {item.count.resource}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          component="div"
                          count={filteredSystems.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handlePageChange}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </>
                    )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Content>
    </Page>
  );
};

export default Analytics;