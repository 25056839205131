/* eslint-disable no-nested-ternary */

import { useEffect, useState } from 'react';

export function useLargeScreenDimensions() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize(); // Initialize dimensions

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define breakpoints
  const breakpoints = {
    large: 1920,
    medium: 1728,
    small: 1440,
  };

  // Determine the adjusted width and height based on screen size
  const width = dimensions.width >= breakpoints.large
    ? 1700 // For large screens (1920px and above), fix the width
    : dimensions.width >= breakpoints.medium
    ? 1380 // For medium screens, set a slightly smaller width
    : dimensions.width >= breakpoints.small
    ? 1200 // For small screens, set a smaller width
    : dimensions.width; // For smaller screens, use the full width with scrolling

  const height = dimensions.width >= breakpoints.large
    ? 1000 // For large screens (1920px and above), fix the height
    : dimensions.width >= breakpoints.medium
    ? 800 // For medium screens, set a slightly smaller height
    : dimensions.width >= breakpoints.small
    ? 700 // For small screens, set a smaller height
    : dimensions.height; // For smaller screens, use the full height with scrolling

  return { width, height };
}
