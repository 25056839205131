import { EmptyState, InfoCardVariants, } from "@backstage/core-components";
import {  EntityRefLink, catalogApiRef, useEntity, } from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import React, {useState } from "react";
import {parseEntityRef} from '@backstage/catalog-model';
import { useAsync } from "react-use";
import { useApi } from "@backstage/core-plugin-api";
import PencilIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    flexGrow:2,
    flexBasis:1,
    flexWrap: 'wrap',
    marginTop: '20px',
    position: 'relative'
  },
  cardData: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    minHeight: '180px',
    paddingTop:'8px',
    width: '170px',
    marginBottom:'10px'
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight:'1.6',
    paddingTop: '90px',
  },
  title: {
    color: '#05164d',
  },
  avatar: {
    color: 'transparent',
    objectFit: 'cover',
    textAlign: 'center',
    width:'100px',
    height:'100px',
    position: 'absolute',
    marginTop: '-20px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent:'space-between',
    '& button': {
      height: '34px',
      lineHeight: '10px',
      marginTop: '16px',
      cursor: 'pointer',
      marginRight:'20px',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'darkblue',
      '& hover':{
        color:'red'
      }
    },
  },

});

interface RolesListProps {
  title?: string,
  variant?: InfoCardVariants,
}

type Team = {
  link: string,
  role: string
}

function DisplayCard(props: Team) {
  const classes = useStyles();
  const { link, role } = props;
  const catalogApi = useApi(catalogApiRef);
  const entityRef =  parseEntityRef(link);
  const { loading,error,value } =  useAsync(() => catalogApi.getEntityByRef(entityRef));

  if (loading) {
    return (<div>Load data...</div>);
  }

  if (error) {
    return (<div>No data</div>);
  }

  if (value) {
    // @ts-ignore
    const {spec: {profile: {displayName, picture}}} = value;

    return (
      <Box>
        <Card variant="elevation" className={classes.cardData}>
          <Avatar alt={displayName} src={picture} className={classes.avatar}/>
          <EntityRefLink
            entityRef={entityRef}
            className={classes.name}
            title={displayName}
          />
          <label className={classes.title}>{role}</label>
        </Card>
      </Box>
    );
  }

  return null;
}

export function RolesListCard(props: RolesListProps) {
  const { title, variant } = props;
  const { entity } = useEntity();
  const rolesAndResponsibilities: Team[] = entity?.spec?.teams as Team[];
  const yamlLink = useState(entity?.metadata?.annotations!!["backstage.io/edit-url"]);

  const classes = useStyles();

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }


 

  const handleOnClick = () => {
    return window.open('https://dock.dh.lufthansa-group.com/docs/default/System/DH-Dock/tutorials/onboarding/#step-6-setup-group-information', '_blank');
  };

  return (
    <Card className={cardClass}>
      <div className={classes.cardHeader}>
      <CardHeader
        title={title} />
        {yamlLink && <button onClick={handleOnClick}><PencilIcon/></button>}
      </div>
     
      <CardContent className={`${classes.container}${cardContentClass}`}>
       
        {rolesAndResponsibilities ? (
          rolesAndResponsibilities).map((row) => (
            <DisplayCard key={row.link} {...row} />
          )) : (
          <EmptyState
            missing="data"
            title="No Contacts"
          />
        )}
      </CardContent>
    </Card>
  );
}
