import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  TrackSpaceUserExtension
} from './trackspaceCustomExtension';
import {
  TrackSpaceUserList
} from './trackspaceAssignee';

export const TrackSpaceExtension: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'trackSpaceUser',
    component: TrackSpaceUserExtension,
  }),
  
);
export const TrackSpaceUserListExtension: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'trackSpaceUserList',
    component: TrackSpaceUserList,
  }),
);