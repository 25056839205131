import { Alert, AlertTitle } from "@material-ui/lab"
import React from "react";

interface messageProps{
  type:'info'|'success'|'error';
  message:string
}
export function ErrorPage({message,type}:messageProps){
  return (
    <Alert severity={type}>
      <AlertTitle>An error occured</AlertTitle>
      <strong>{message}</strong>
    </Alert>
  ); 
}
export function InfoPage({message,type}:messageProps){
  return (
    <Alert severity={type}>
      <strong>{message}</strong>
    </Alert>
  ); 
}