import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';

export const isAzureProjectExists = async (name: string) => {
  return fetchData(`${urls.isAzureProjectExists}${name}`);
};

export const getPipelineDataByTemplateName = async (name: string) => {
  return fetchData(`${urls.getPipelineDataByTemplateName}${name}`);
};

export const getOwnershipCount = async (name: string) => {
  return fetchData(`${urls.getOwnershipCount}${name}`);
};
