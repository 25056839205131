import { useEffect, useState } from 'react';
import mermaid from 'mermaid';
import { MermaidProps } from './props';
import React from 'react';

let diagramId = 0;

const makeDiagram = async (el: HTMLElement, diagramText: string) => {
  el.style.display = 'none';

  const diagramElement = document.createElement('div');
  diagramElement.className = 'mermaid';

  el.parentNode?.insertBefore(diagramElement, el.nextSibling);

  const id = `mermaid-${diagramId++}`;

  const { svg } = await mermaid.render(id, diagramText);
  diagramElement.innerHTML = svg;
  return svg;
};

interface MermaidAddonProps {
  retrievedContent: string;
  mermaidProps?: MermaidProps;
}

export const MermaidAddon: React.FC<MermaidAddonProps> = ({
  retrievedContent,
}) => {
  const [response, setResponse] = useState<any>();
  useEffect(() => {
    mermaid.initialize({ startOnLoad: true });

    const renderMermaid = async () => {
      const svg = await makeDiagram(
        document.createElement('div'),
        retrievedContent,
      );
      setResponse(svg);
      return svg;
    };
    renderMermaid();
  }, [retrievedContent]);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: response }} />
    </div>
  );
};
