import { Content, Header, Page } from '@backstage/core-components';
import React, { FC} from 'react';

import './Reports.css';


const Report: FC<{ url?: string }> = ({ url }) => {
  
  return (
    <>
      {!url ? (
        <Page themeId="tool">
          <Header title="Report" />
          <Content>
            <div className="responsive-iframe-container">
              <iframe
                src={
                  url ??
                  'https://app.powerbi.com/reportEmbed?reportId=72ffcc4f-1857-4b32-925a-37ab2d1bd468&autoAuth=true&ctid=72e15514-5be9-46a8-8b0b-af9b1b77b3b8'
                }
              />
            </div>
          </Content>
        </Page>
      ) : (
        <div
          className="responsive-iframe-container"
          style={{ marginTop: '70px' }}
        >
          <iframe src={url} />
        </div>
      )}
     
    </>
  );
};

export default Report;
