/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Progress } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { RadarEntry, techRadarApiRef, TechRadarLoaderResponse } from '../api';
import Radar from '../components/Radar';

import type { Entry } from '../utils/types';

const useTechRadarLoader = (id: string | undefined) => {
  const errorApi = useApi(errorApiRef);
  const techRadarApi = useApi(techRadarApiRef);

  const { error, value, loading } = useAsync(
    async () => techRadarApi.load(id),
    [techRadarApi, id],
  );

  useEffect(() => {
    if (error) {
      errorApi.post(error);
    }
  }, [error, errorApi]);

  return { loading, value, error };
};

function matchFilter(filter?: string): (entry: RadarEntry) => boolean {
  const terms = filter
    ?.toLocaleLowerCase('en-US')
    .split(/\s/)
    .map(e => e.trim())
    .filter(Boolean);

  if (!terms?.length) {
    return () => true;
  }

  return entry => {
    const text = `${entry.title} ${
      entry.timeline[0]?.description || ''
    }`.toLocaleLowerCase('en-US');
    return terms.every(term => text.includes(term));
  };
}

/**
 * Properties of {@link TechRadarComponent}
 *
 * @public
 */
export interface TechRadarComponentProps {
  /**
   * ID of this Tech Radar
   *
   * @remarks
   *
   */
  id?: string;
  /**
   * Width of Tech Radar
   */
  width: number;
  /**
   * Height of Tech Radar
   */
  height: number;
  /**
   * Custom React props to the `<svg>` element created for Tech Radar
   */
  svgProps?: object;
  /**
   * Text to filter {@link RadarEntry} inside Tech Radar
   */
  searchText?: string;
  /**
   * Custom techradar from location
   */
  entries?: any[];

  allEntities?: any[];
  filteredResults?: any[];
  isSearchEmpty?:boolean;
  setTechRadarApiResponse?: (data: TechRadarLoaderResponse) => void;
  onFilter?: (tag: string, isChecked: boolean) => void;

  getTagsList?: (tagList: string[]) => void;
}

/**
 * Main React component of Tech Radar
 *
 * @remarks
 *
 * For advanced use cases. Typically, you want to use {@link TechRadarPage}
 *
 * @public
 */

export function RadarComponent(props: TechRadarComponentProps) {
  const tagList: string[] = [];
  const { loading, error, value: data } = useTechRadarLoader("backend");
  if (data && data.entries.length > 0) {
    props.setTechRadarApiResponse?.(props.entries as any);
  }

  let arr: never[] = [];
  if (props.entries && props.entries!!.length > 0) {
    arr = props.entries[0].entries as any;
    const tags = arr
      ?.filter((x: { tags: string[] }) => x.tags as any)
      .map((tag: any) => {
        return tag.tags;
      });
    props.getTagsList?.(tags!!);
  }

  const mapToEntries = (
    loaderResponse: TechRadarLoaderResponse,
  ): Array<Entry> => {
    const allEntiries =
      props?.filteredResults!!.length > 0
        ? props.filteredResults
        : loaderResponse.entries.concat(arr ?? []);
    props.allEntities?.push(allEntiries);
    return allEntiries!!
      ?.filter(matchFilter(props.searchText))
      .filter((item, index) => {
        return (
          allEntiries?.findIndex(_item => item.title === _item.title) === index
        );
      })
      .map(entry => ({
        id: entry.key,
        quadrant: loaderResponse.quadrants.find(q => q.id === entry.quadrant)!,
        title: entry.title,
        ring: loaderResponse.rings.find(
          r => r.id === entry.timeline[0].ringId,
        )!,
        timeline: entry.timeline.map(
          (e: { date: any; ringId: string; description: any; moved: any }) => {
            return {
              date: e.date,
              ring: loaderResponse.rings.find(a => a.id === e.ringId)!,
              description: e.description,
              moved: e.moved,
            };
          },
        ),
        moved: entry.timeline[0].moved,
        description: entry.description || entry.timeline[0].description,
        url: entry.url,
        links: entry.links,
        tags: entry.tags?.map((tag: string) => {
          tagList.push(tag);
          return {
            tag,
          };
        }),
      }));
  };

  if(props.isSearchEmpty){
    return (<Radar
          {...props}
          rings={data!!.rings}
          quadrants={data!!.quadrants}
          entries={[]}
        />)
  }

  return (
    <>
      {loading && <Progress />}
      {!loading && !error && data && (
        <Radar
          {...props}
          rings={data.rings}
          quadrants={data.quadrants}
          entries={mapToEntries(data)}
        />
      )}
    </>
  );
}
