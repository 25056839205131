import { Entity } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useMemo, useState } from 'react';

import ownedImage from '../../assets/icons/owned.svg';
import starredImage from '../../assets/icons/star.png';
import { getAllSystems, getSystemsByGroupName } from '../../services/systems.service';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type SelectedOwned = 'owned' | 'starred' | 'all';

interface Styles {
  display: string;
  cursor: string;
  justifyContent: string;
  marginLeft: number | string;
  width: string;
  flexDirection: FlexDirection;
  padding: number;
  borderRadius: number;
  backgroundColor: string;
}

const styles: { [key: string]: Styles | any } = {
  header: {
    fontSize: 13,
    fontWeight: 'bold',
    marginTop: -1,
    marginLeft: 0,
  },
  body: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    marginLeft: -1,
    width: '100%',
    flexDirection: 'row',
    padding: 1,
    borderRadius: 3,
    backgroundColor: '#fff',
  },
  details: {
    marginLeft: '10px',
  },
  left: {
    marginLeft: '10px',
  },
  right: {
    marginRight: '10px',
    marginTop: '18px',
  },
  icons: {
    maxWidth: '20px',
    lineHeight: '40px',
    marginTop: '15px',
    marginRight: '5px',
  },
  text: {
    fontSize: '1rem',
    fontFamily: 'Helvetica Neue',
    fontWeight: '350',
    lineHeight: '1.5',
    position: 'absolute',
    marginTop: '15px',
  },
  all: {
    marginLeft: '5px',
    fontFamily: 'Helvetica Neue',
    fontWeight: '400',
    lineHeight: '1.5',
  },
};

const PersonalInfo: FC<{ selectedItem: (value: string) => void }> = ({
  selectedItem,
}) => {
  const [selected, setSelected] = useState<SelectedOwned>('owned');
  const [ownerCount, setOwnerCount] = useState(0);
  const [allSystemsArr, setAllSystemsArr] = useState<Entity[]>([]);
  const identityApi = useApi(identityApiRef) as any;
  const handleSelect = (selection: SelectedOwned) => {
    switch (selection) {
      case 'owned': {
        selectedItem?.('owned');
        break;
      }
      case 'all': {
        selectedItem?.('all');
        break;
      }

      default: {
        return '';
      }
    }

    setSelected(selection);
    return selection;
  };
  const getSystemCount = useMemo(async () => {
    if (window.location.search) {
      try {
        const group =
          identityApi?.target?.identity?.ownershipEntityRefs[1].split('/')[1];
        const systemCount = await getSystemsByGroupName(group);
        return systemCount;
      } catch (error) {
        return 0;
      }
    }
    return [];
  }, [identityApi]);

  const getAllSystemArr = useMemo(async () => {
    if (window.location.search) {
      try {
        const AllSystems = await getAllSystems();
        return AllSystems;
      } catch (error) {
        return 0;
      }
    }
    return [];
  }, []);
  useEffect(() => {
    getSystemCount.then(res => {
      setOwnerCount(res?.length);
    });
    getAllSystemArr.then(res => {
      setAllSystemsArr(res);
    });
  }, [getAllSystemArr, getSystemCount]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(0,0,0,.11)',
      }}
    >
      <Typography variant="h6" sx={styles.header} style={{ marginBottom: 10 }}>
        PERSONAL
      </Typography>

      <div
        role="button"
        tabIndex={0}
        style={{
          ...styles.body,
          backgroundColor: selected === 'owned' ? 'rgba(0,0,0,.11)' : 'white',
        }}
        onClick={() => handleSelect('owned')}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleSelect('owned');
          }
        }}
        onTouchStart={() => {}}
        onTouchEnd={() => {}}
      >
        <div style={styles.left}>
          <img src={ownedImage} style={styles.icons} alt="Owned" />
          <span style={styles.text}>Owned</span>
        </div>
        <p style={styles.right}>{ownerCount}</p>
      </div>

      <div
        role="button"
        tabIndex={0}
        style={{
          ...styles.body,
          backgroundColor: selected === 'starred' ? 'rgba(0,0,0,.11)' : 'white',
        }}
        onClick={() =>{}}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleSelect('starred');
          }
        }}
        onTouchStart={() => {}}
        onTouchEnd={() => {}}
      >
        <div style={styles.left}>
          <img src={starredImage} style={styles.icons} alt="Owned" />
          <span style={styles.text}>Starred</span>
        </div>
        <p style={styles.right}>0</p>
      </div>

      <Typography
        variant="h6"
        sx={styles.header}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        DH DOCK
      </Typography>
      <div
       role="button"
        tabIndex={0}
        style={{
          ...styles.body,
          backgroundColor: selected === 'all' ? 'rgba(0,0,0,.11)' : 'white',
        }}
        onClick={() => handleSelect('all')}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleSelect('all');
          }
        }}
      >
        <section style={styles.left}>
          <span style={styles.text}>All</span>
        </section>
        <p style={styles.right}>{allSystemsArr.length}</p>
      </div>
    </Box>
  );
};

export default PersonalInfo;
