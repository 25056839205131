

import { Entity } from "@backstage/catalog-model";
import { EmptyState } from "@backstage/core-components";
import {
    EntityAzurePipelinesContent,
  } from '@backstage/plugin-azure-devops';import { EntitySwitch } from "@backstage/plugin-catalog";
import { Button } from "@material-ui/core";
import React from "react";

export const pipelinesContent = (isAzurePipelinesAvailable: (entity: Entity) => boolean) => (
    <EntitySwitch>
       <EntitySwitch.Case if={isAzurePipelinesAvailable}>
         <EntityAzurePipelinesContent defaultLimit={25} />
       </EntitySwitch.Case>
   
       <EntitySwitch.Case>
         <EmptyState
           title="No pipelines available for this entity"
           missing="info"
           description="You need to add an annotation to your component if you want to show associated pipelines"
           action={
             <Button
               variant="contained"
               color="primary"
               href="https://github.com/backstage/backstage/blob/master/plugins/azure-devops/README.md#azure-pipelines-only"
             >
               Read more
             </Button>
           }
         />
       </EntitySwitch.Case>
     </EntitySwitch>
   );
   
