import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles(() => {
  return {
    value: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
  };
});

export const Value = (props: { value?: string }) => {
  const classes = useStyles();
  return (
    <Typography component="span" className={classes.value}>
      {props.value}
    </Typography>
  );
};
