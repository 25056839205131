import { EntityLayout } from "@backstage/plugin-catalog";
import { EntityOwnershipCard, EntityUserProfileCard } from "@backstage/plugin-org";
import { Grid } from "@material-ui/core";
import React from "react";

import { CustomEntityLayout } from "../../../pages/EntityLayout";

export const userPage = (entityWarningContent: JSX.Element) => (
    <CustomEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={12}>
            <EntityUserProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </CustomEntityLayout>
  );