import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  AadGroupPicker,
} from './AadGroupPickerExtension'

export const AadGroupPickerFieldExtension: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AadGroupPicker',
    component: AadGroupPicker,
  }),
);
