import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

import { ErrorPage } from '../../components/ui/Error';
import { getAllDevSecOpsEpics, getEpicAvatarArr } from '../../services/jira.service';
import JiraCard, { JiraCardProps } from './JiraCard';

import './jira.css';

interface EpicDataProps {
  issuesData: {
    issues: [JiraCardProps];
  };
}

enum jiraStatusProps {
  todo = '10080',
  todo2 = '10082',
  inProgress = '3',
  inProgress2 = '10002',
  onHold = '10019',
  onHold2 = '10045',
  done = '10081',
}

let uniqueAvatarList: any[] = [];
const JiraBoard = () => {
  document.title='Epics'
  const [epicList, setEpicList] = useState<JiraCardProps[]>([]);
  const [avatarList, setAvatarList] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [toDoList, setToDoList] = useState<JiraCardProps[]>([]);
  const [inProgressList, setinProgressList] = useState<JiraCardProps[]>([]);
  const [onHoldList, setOnHoldList] = useState<JiraCardProps[]>([]);
  const [doneList, setDoneList] = useState<JiraCardProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const getAllEpics = useMemo(async () => {
    try {
      const data: EpicDataProps = await getAllDevSecOpsEpics();
      setIsLoading(false);
      return data.issuesData.issues;
    } catch (error_) {
      if (error_ instanceof Error) {
        setIsLoading(false);
        setError(error_.message);
      }
      return null;
    }
  }, []);

  function getUniqueAssigneeNames(arr: JiraCardProps[]) {
    const uniqueNames: { [key: string]: boolean } = {};
    if(arr && arr?.length>1){
      for (let i = 0; i < arr.length; i++) {
        const assigneeName = arr[i].assigneeName;
        if (!uniqueNames[assigneeName]) {
          uniqueNames[assigneeName] = true;
        }
      }
      uniqueAvatarList = Object.keys(uniqueNames);
    }
  }

  useEffect(() => {
    const getData = async () => {
      const data: JiraCardProps[] = (await getAllEpics) as JiraCardProps[];
      if (data) {
        getUniqueAssigneeNames(data);
        if(data.length>0){
          const response = [...data];
          const todoList_ = response.filter(
            x =>
              x.statusId === jiraStatusProps.todo ||
              (x.statusId === jiraStatusProps.todo2 && !x.done),
          );
          const onHoldList_ = response.filter(
            x => x.statusId === jiraStatusProps.onHold && !x.done,
          );
          const inProgressList_ = response.filter(
            x =>
              x.statusId === jiraStatusProps.inProgress ||
              x.statusId === jiraStatusProps.inProgress2 ||
              (x.statusId === jiraStatusProps.onHold2 && !x.done),
          );
          const doneList_ = response.filter(
            x => x.statusId === jiraStatusProps.done,
          );
          setToDoList(todoList_);
          setOnHoldList(onHoldList_);
          setinProgressList(inProgressList_);
          setDoneList(doneList_);
          setEpicList(data);
          const avatar_List = await getAllAvatars();
          setAvatarList(avatar_List);
        }
      }
    };
    getData();
  }, [getAllEpics]);

  async function getAllAvatars() {
    const data = await Promise.all(
      uniqueAvatarList.map(async item => {
        const _item = item
          .replace('(LDH)', '')
          .replace('(LX)', '')
          .replace('LSY', '')
          .replace('()', '');
        const name = _item.includes(',')
          ? _item
              .split(',')[1]
              .replace('%20', ' ')
              .trim()
              .concat(
                `.${_item.split(',')[0].replace(' ', '').replace('%20', ' ').trim()}`,
              )
              .toLocaleLowerCase()
          : _item.replace('%20', ' ').replace(' ', '.').toLocaleLowerCase();
        const avatarData = await getEpicAvatarArr(name);
        avatarData.prevName = item;
        return avatarData;
      }),
    );
    return data;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isLoading && error && epicList.length < 1) {
    return <ErrorPage message='Epics could not be loaded' type='error'/>
  }

  return (
    <div className="container">
      <div className="jiraBoardContainer">
        <div className="jiraBoardContainer__header">
          TO DO {toDoList.length}
        </div>
        {toDoList.map(item => (
          <JiraCard data={item} avatarList={avatarList} key={item.id} />
        ))}
      </div>
      <div className="jiraBoardContainer">
        <div className="jiraBoardContainer__header">
          IN PROGRESS {inProgressList.length}
        </div>
        {inProgressList.map(item => (
          <JiraCard data={item} avatarList={avatarList} key={item.id} />
        ))}
      </div>
      <div className="jiraBoardContainer">
        <div className="jiraBoardContainer__header">
          ON HOLD {onHoldList.length}
        </div>
        {onHoldList.map(item => (
          <JiraCard data={item} avatarList={avatarList} key={item.id} />
        ))}
      </div>
      <div className="jiraBoardContainer">
        <div className="jiraBoardContainer__header">
          DONE {doneList.length}
        </div>
        {doneList.map(item => (
          <JiraCard data={item} avatarList={avatarList} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default JiraBoard;
