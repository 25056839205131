import { configureStore } from '@reduxjs/toolkit';
import ownershipReducer from './slices/ownerShipSlice'; // Adjust path if necessary
import dhDockComponentReducer from './slices/dhDockComponentStatusSlice'; // Adjust path if necessary
import { logger } from 'redux-logger';
import { ownersProps } from './slices/ownerShipSlice'; // Import the type

// Define the store state type
export type RootState = {
  ownersProps: ownersProps; // Ensure this matches the state structure
  dhDockComponentStatus:boolean;
};

// Configure the store
export const dhDockStore = configureStore({
  reducer: {
    ownersProps: ownershipReducer,
    dhDockComponentStatus:dhDockComponentReducer,
  },
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

// Infer the `AppDispatch` type from the store
export type AppDispatch = typeof dhDockStore.dispatch;
