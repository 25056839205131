import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  OIPFileUploadExtension
} from './fileUpload';

export const OIPFileUpload: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'oipFileUploadExtension',
    component: OIPFileUploadExtension,
  }),
  
);
