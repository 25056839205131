import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import { fetchData, hostUrl, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Avatar, Box, Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useBackStageApi from '../../hooks/useBackstageApi';

type MemberType = 'contributor' | 'core';

interface MemberListProps {
  targetRef: string;
  type: string;
  role?: string;
}

interface UserRoleProps {
  link: string;
  role: string;
}

type Team = {
  link?: string;
  role?: string;
  targetRef: string;
  champion?: 'DevOps Champion' | 'Security Champion' | 'DevOps & Security Champion'| 'none';
};

interface ChampsProps {
  targetRef: string;
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    minHeight: '35vh',
    height: 'auto',
  },
  emptyCard: {
    marginLeft: 15,
  },
  cardHeader: {
    display: 'flex',
    '& button': {
      height: '34px',
      lineHeight: '10px',
      marginTop: '16px',
      cursor: 'pointer',
    },
  },

  cardData: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    minHeight: '120px',
    paddingTop: '10px',
    marginTop: '40px',
    maxWidth: '200px',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    gap: '18px',
    flexWrap: 'wrap',
  },
  name: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '1.6',
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '10px',
  },
  role: {
    color: '#05164d',
    position: 'relative',
    bottom: 0,
  },
  avatar: {
    color: 'transparent',
    textAlign: 'center',
    position: 'absolute',
    height: '3em',
    width: '4rem',
    marginTop: '-40px',
  },
});

function DisplayCard({ targetRef, role, champion }: Team) {
  const classes = useStyles();
  const { value } = useBackStageApi(targetRef);

  if (value) {
    const {
      spec: {
        profile: { displayName, picture },
      },
    } = value as any;
    return (
      <Box>
        <Card variant="elevation" className={classes.cardData}>
          <Avatar alt={displayName} src={picture} className={classes.avatar} />
          <EntityRefLink
            entityRef={value}
            className={classes.name}
            title={displayName.replace(',', '')}
          />
          <label className={classes.role}>{role}</label>
          <br />
          {champion !== 'none' && (
            <label className={classes.role}>{champion}</label>
          )}
        </Card>
      </Box>
    );
  }
  return null;
}

const MembersList: React.FC<{
  members: MemberListProps[];
  name: string;
  groupInfoEntity?: Entity;
  memberType: MemberType;
}> = ({ members, name, groupInfoEntity, memberType }) => {
  document.title = name;
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [groupContributorList, setGroupContributorList] = useState<
    MemberListProps[]
  >([]);
  const { entity } = useEntity();
  const data = members.filter(x => x.type === 'hasMember');

  const [roleList, setRoleList] = useState<UserRoleProps[]>([]);
  const [teamName, setTeamName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [formatedRoles, setFormatedRoles] = useState<MemberListProps[]>([]);
  const [champs, setChamps] = useState({
    securityChamps: [] as ChampsProps[],
    devopsChamps: [] as ChampsProps[],
  });

  const fetchRoles = useCallback(() => {
    if (groupInfoEntity) {
      const { spec } = groupInfoEntity as any;
      const userRoles = spec?.userRoles as UserRoleProps[];

      if (userRoles) {
        setRoleList(userRoles);
      }
    }
  }, [groupInfoEntity]);
  const getTeamNameByTeamId = useMemo(async () => {
    const groupItem = {
      kind: 'group',
      name: name ?? 'dh_team_dso',
      namespace: 'default',
    };
    const value = (await catalogApi.getEntityByRef(groupItem)) as any;
    return value?.spec.profile?.displayName;
  }, [name, catalogApi]);

  useEffect(() => {
    if (data.length > 0) {
      fetchRoles();
    }
  }, [data, fetchRoles]);

  useEffect(() => {
    getTeamNameByTeamId.then(response => {
      setTeamName(response);
    });
  }, [name, data, getTeamNameByTeamId]);

  /* eslint-disable */
  useEffect(() => {
    HandleSort();
  }, [roleList.length > 0]);

  useEffect(() => {
    const getContributorMembers = async () => {
      try {
        const name = entity.metadata.name.concat('_contributors');
        const response = await fetchData(
          `${urls.getContributorsByTeam}${name}`,
        );
        const data = response.relations?.filter(
          (x: MemberListProps) => x.type === 'hasMember',
        );
        setIsLoading(false);
        setGroupContributorList(data);
      } catch (exception) {
        if (exception instanceof Error) {
          setIsLoading(false);
        }
      }
    };
    getContributorMembers();
  }, [memberType === 'contributor']);

  const getAllDevopsChampionsList = useCallback(async () => {
    try {
      const devopsChampionsList = await fetchData(
        `api/catalog/entities/by-name/group/default/dh_champ_devops`,
      );
      setChamps(prev => ({
        ...prev,
        devopsChamps: devopsChampionsList?.relations?.filter(
          (x: { type: string }) => x.type === 'hasMember',
        ),
      }));
    } catch (error) {
      setChamps(prev => ({
        ...prev,
        devopsChamps: [],
      }));
    }
  }, [hostUrl]);
  const getAllSecurityChampionsList = useCallback(async () => {
    try {
      const securityChampionsList = await fetchData(
        `api/catalog/entities/by-name/group/default/dh_champ_security`,
      );

      setChamps(prev => ({
        ...prev,
        securityChamps: securityChampionsList?.relations?.filter(
          (x: { type: string }) => x.type === 'hasMember',
        ),
      }));
    } catch (error) {
      setChamps(prev => ({
        ...prev,
        securityChamps: [],
      }));
    }
  }, []);

  useEffect(() => {
    getAllDevopsChampionsList();
    getAllSecurityChampionsList();
  }, []);

  const HandleSort = () => {
    setIsLoading(false);
    const formatData = data.map(x => {
      x.role = roleList?.find(z => z.link === x.targetRef)?.role;
      return x;
    });
    setFormatedRoles(formatData);
  };

  return (
    <Card className={memberType === 'core' ? classes.card : ''}>
      {isLoading && <LinearProgress />}

      <div className={classes.cardHeader}>
        <CardHeader
          title={`${memberType === 'core' ? `Core Members (${data.length})` : `Contributor Members (${groupContributorList?.length ?? 0})`} `}
        />
      </div>

      {data.length > 0 && (
        <label className={classes.emptyCard}> of {teamName ?? name}</label>
      )}
      <label className={classes.emptyCard}>
        {data.length < 1 && 'No members found'}
      </label>

      <CardContent className={classes.cardContent}>
        {memberType === 'core' &&
          formatedRoles?.length > 0 &&
          formatedRoles
            .sort((a: any, b: any) => {
              return a.role?.localeCompare(b.role);
            })
            .map(x => {
              return (
                <React.Fragment key={x.targetRef}>
                  <DisplayCard
                    key={x.targetRef}
                    targetRef={x.targetRef}
                    role={x
                      .role!!?.replace('/ DevOps Champion', '')
                      .replace('/ Security Champion', '')
                      .replace('DevOps Champion', '')
                      .replace('Security Champion', '')}
                    champion={
                      champs.devopsChamps.concat(champs.securityChamps).filter(a=>a.targetRef===x.targetRef).length>1?
                      'DevOps & Security Champion':
                      champs.devopsChamps.find(y => y.targetRef === x.targetRef)
                        ? 'DevOps Champion'
                        : champs.securityChamps.find(
                              z => z.targetRef === x.targetRef,
                            )
                          ? 'Security Champion'
                          : 'none'
                    }
                  />
                </React.Fragment>
              );
            })}

        {memberType === 'contributor' &&
          groupContributorList?.length > 0 &&
          groupContributorList.map(x => {
            return (
              <React.Fragment key={x.targetRef}>
                <DisplayCard
                  key={x.targetRef}
                  targetRef={x.targetRef}
                  role={x.role!!}
                />
              </React.Fragment>
            );
          })}
      </CardContent>
    </Card>
  );
};

export default MembersList;
