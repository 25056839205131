import { FieldExtensionComponentProps } from "@backstage/plugin-scaffolder-react"
import { Autocomplete, TextField } from "@mui/material"
import { fetchData, urls } from "@internal/backstage-plugin-dh-dock-shared-common"
import React, { useEffect, useState } from "react"

interface userProps{
  user:string
}
export const TrackSpaceUserList = ({
  onChange,

}: FieldExtensionComponentProps<string>) => {
  const [users,setUsers]=useState<userProps[]>([])

  useEffect(()=>{
      const fetchAllUsers=async()=>{
        const userList=await fetchData(urls.getAllUsers);
        setUsers(userList)
      }
      fetchAllUsers();
  },[])

  const handleOnChange = async (value:string) => {
    const _user=value.replace('user:default/','').replace('_','@')
    const user=await fetchData(`${urls.getTrackSpaceUser}${_user}`)
    onChange(user[0].name); // Updates state with the selected user
};

  return (
    <>
    
    Assignee<Autocomplete
    options={users}
    id="disable-close-on-select"
    disableCloseOnSelect
    onChange={(_event, value) => handleOnChange(value?.user!!)}
    getOptionLabel={(option)=>option.user}
    renderInput={(params) => (
      <TextField {...params} label="" variant="standard" />
    )}
  />
    </>
    
  )
}

