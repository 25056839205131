import { parseEntityRef } from "@backstage/catalog-model";
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { useAsync } from "react-use";



export default function useBackStageApi(targetRef:any){
    const catalogApi = useApi(catalogApiRef);
    const entityRef =  parseEntityRef(targetRef);
    const { loading,error,value } =  useAsync(() => catalogApi.getEntityByRef(entityRef));
    return {value,error,loading}
}