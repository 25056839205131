import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';

interface featureFlagsProps {
  id: string;
}
export default function useFeatureFlags({ id }: featureFlagsProps) {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const isOn = featureFlagsApi.isActive(id);
  return { isOn };
}
