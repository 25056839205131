import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { CustomDomainEntityLayout } from '../../../pages/EntityLayout/CustomDomainEntityLayout';
import { overviewContent } from '../content/overviewContent';
import { techdocsContent } from '../content/techdocsContent';
import PipelineHistory from '../../../pages/PipelineHistory';
import { isAdrAvailable } from '@backstage/plugin-adr';
import ADRList from '../../../pages/Adr';

export const templatePage = () => (
  <CustomDomainEntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pipelinesHistory" title="Pipeline History">
      <PipelineHistory />
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adr" title="ADR">
      <ADRList />
    </EntityLayout.Route>
  </CustomDomainEntityLayout>
);
