import { useEntity } from '@backstage/plugin-catalog-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import  LinearProgress  from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';

import { RenderMarkDown } from '../../components/CustomMarkDownReader';

const useStyles = makeStyles({
  
  container:{
    position:'relative'
  },
  button: {
      height: '34px',
      lineHeight: '10px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
    border: 'none',
      color: 'darkblue',
      float:'right',
      marginRight:'20px',
      marginTop:'-40px',
      '& hover':{
        color:'red'
      }
    }
}
);

const CustomDomainAboutUs = () => {
  const {entity}=useEntity();
  const [aboutData,setAboutData]=useState('');
  const [isLoading,setIsLoading]=useState(true);
  const [aboutLink,setAboutLink]=useState('');
  const [baseLocation,setBaseLocation]=useState('');
  const [baseUrl,setBaseUrl]=useState('');
  const classes = useStyles();
  useEffect(()=>{
    let editUrl:string='';
    const getYaml=async()=>{
      try {
        const aboutLocation = entity?.metadata?.annotations!!['backstage.io/about-location'] as string;
        editUrl=entity?.metadata?.annotations!!["backstage.io/edit-url"] as string;
        const backstageLocation=entity?.metadata?.annotations!!["backstage.io/source-location"].replace('%2F', '/')
        .concat(
          aboutLocation
        )
        .replace('url:', '')
        .replace('/system%2Fadr', '/system/adr');
        if(aboutLocation){
          const aboutData_=await fetchData(`${urls.getAdrContentByName}${backstageLocation}`);
          const parts = backstageLocation?.split('/');
          parts.pop();
          setBaseUrl(parts.join('/'));
          setAboutData(aboutData_.data)
          setIsLoading(false);
          setAboutLink(backstageLocation);
        }
       
       
      } catch (error) {
        if(error instanceof Error){
          setIsLoading(false)
          return null;

        }
      }
      finally{
        setBaseLocation(editUrl)
      }
      setIsLoading(false)
     return null;
    };

    getYaml();
   
  },[entity?.metadata?.name])

  

  const onEdit=()=>{
    let url;
    if(aboutLink){
      url=aboutLink
    }
    else{
      url=baseLocation
    }
    return window.open(url, '_blank');
  }

  
  return (
    <Card>
      <CardHeader title="About" />
      <div className={classes.container}
      role="button" 
      tabIndex={0}
      onClick={onEdit}
      onKeyDown={onEdit}
      >
        <button className={classes.button}><Edit/></button>
      </div>
      <CardContent>
        {isLoading && <LinearProgress/>}
        <div className="markdown-content" id="another-markdown-container">
        <RenderMarkDown retrievedContents={aboutData} url={baseUrl} />
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomDomainAboutUs;
