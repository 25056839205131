import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Define the state type
export interface ownersProps {
  success:boolean; // Assuming owners is an array of strings
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ownersProps = {
  success:false,
  loading: false,
  error: null,
};
export const runComponentAzureDevOpsAsync = createAsyncThunk<string[], string>(
  'dhDock/runComponentAzureDevOps',
  async (token: string) => {
    const response = await fetchData(`${urls.runComponentAzurePipelineStatus}${token}`);
    return response; // Assuming this returns an array of strings
  }
);


export const dhDockComponentSlice = createSlice({
  name: 'dhDockComponent',
  initialState,
  reducers: {},
  // Handle async actions in extraReducers
  extraReducers: builder => {
    builder
      // Pending state: when the fetch request is started
      .addCase(runComponentAzureDevOpsAsync.pending, state => {
        state.loading = true;
        state.error = null;
      })
      // Fulfilled state: when the fetch request is successful
      .addCase(
        runComponentAzureDevOpsAsync.fulfilled,
        (state,action:any) => {
          state.success = action.payload;
          state.loading = false;
        },
      )
      // Rejected state: when the fetch request fails
      .addCase(runComponentAzureDevOpsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to run job';
      });
  },
});

// Export the reducer to be added to the store
export default dhDockComponentSlice.reducer;
