import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Input, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useState } from 'react';

import { isAzureProjectExists } from '../../../services/utils.service';

/*
 This is the actual component that will get rendered in the form
*/

export const ValidateAzureDevOpsExtension = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  const [project, setProject] = useState('');
  const [error, setError] = useState('');

  const validateAzureDevOpsProject = async (isKeyUp: boolean) => {
    if (project.trim().length > 0) {
      const isExists = (await isAzureProjectExists(project)) as boolean;
      if (!isExists) {
        setError(
          `(${project}) The Azure Devops Project specified doesn't exist. In order to deploy your product on DCP, you first need to create Azure Devops Project by triggering 'Azure DevOps Product Team Space' template`,
        );
        if (!isKeyUp) setProject('');
        else setError('');
      }
    }
  }
    const handleOnChange = (value: string) => {
      onChange(value);
      setProject(value);
    };
    return (
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        <InputLabel htmlFor="validateName">Azure DevOps Project*</InputLabel>
        <Input
          id="validateName"
          aria-describedby="entityName"
          onChange={e => handleOnChange(e.target?.value)}
          onBlur={() => validateAzureDevOpsProject(false)}
          onKeyUp={() => validateAzureDevOpsProject(true)}
          onFocus={() => validateAzureDevOpsProject(false)}
          value={project}
        />
        <label style={{ color: 'red' }}>{error}</label>
      </FormControl>
    );
  };
