
import React from 'react';
import {
  humanizeEntityRef,
  EntityRefLink,
  EntityRefLinks, catalogApiRef,
  EntityDisplayName
} from '@backstage/plugin-catalog-react';
import { Chip } from '@material-ui/core';
import { CatalogTableRow } from './types';
import { OverflowTooltip, TableColumn } from '@backstage/core-components';
import {Entity, EntityRelation, parseEntityRef} from '@backstage/catalog-model';
import { JsonArray } from '@backstage/types';
import {CustomEntityRelation} from "./CatalogTable";
import {useAsync} from "react-use";
import {useApi} from "@backstage/core-plugin-api";

type ReferenceLinkProps = {
  entity: Entity
}

type GroupReferenceLinkProps = {
  domainEntityRelation: EntityRelation
  groupEntity: Entity
}

export const columnFactories = Object.freeze({
  createNameColumn(options?: {
    defaultKind?: string;
  }): TableColumn<CatalogTableRow> {
    function formatContent(entity: Entity): string {
      return (
        entity.metadata?.title ||
        humanizeEntityRef(entity, {
          defaultKind: options?.defaultKind,
        })
      );
    }

    function GroupReferenceLink(props: GroupReferenceLinkProps): JSX.Element {
      const catalogApi = useApi(catalogApiRef);
      const { domainEntityRelation, groupEntity } = props;
      const { value: domainEntity, loading } = useAsync(() => catalogApi.
        getEntityByRef(parseEntityRef(domainEntityRelation?.targetRef)));

      if (loading) {
        return (<div>Load data...</div>);
      }

      return (
        <EntityRefLink
          entityRef={(domainEntity as Entity)}
        >
          <EntityDisplayName entityRef={groupEntity} />
        </EntityRefLink>
      );
    }

    function ReferenceLink (props: ReferenceLinkProps): JSX.Element {
      const { kind, relations } = props.entity;
      const domainOwner: EntityRelation | undefined = relations?.
        find((v: CustomEntityRelation) => v?.target?.kind === 'domain');

      if (domainOwner && kind === 'Group') {
        return (
          <GroupReferenceLink
            domainEntityRelation={domainOwner}
            groupEntity={props.entity}
          />
        );
      }

      return (
        <EntityRefLink
          entityRef={props.entity}
          defaultKind={options?.defaultKind || 'Component'}
        />
      );
    }

    return {
      title: 'Name',
      field: 'resolved.entityRef',
      highlight: true,
      
      customSort({ entity: entity1 }, { entity: entity2 }) {
        // TODO: We could implement this more efficiently by comparing field by field.
        // This has similar issues as above.
        return formatContent(entity1).localeCompare(formatContent(entity2));
      },
      render: ({ entity }) => (<ReferenceLink entity={entity}/>),
    };
  },
  createSystemColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Product',
      field: 'resolved.partOfSystemRelationTitle',
      render: ({ resolved }) => (
        <EntityRefLinks
          entityRefs={resolved.partOfSystemRelations}
          defaultKind="system"
        />
      ),

    };
  },
  createOwnerColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Owner',
      field: 'resolved.ownedByRelationsTitle',
      render: ({ resolved }) => (
        <EntityRefLinks
          entityRefs={resolved.ownedByRelations}
          defaultKind="group"
        />
      ),
    };
  },
  createSpecTargetsColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Targets',
      field: 'entity.spec.targets',
      render: ({ entity }) => (
        <>
          {(entity?.spec?.targets || entity?.spec?.target) && (
            <OverflowTooltip
              text={(
                (entity!.spec!.targets as JsonArray) || [entity.spec.target]
              ).join(', ')}
              placement="bottom-start"
            />
          )}
        </>
      ),
    };
  },
  createSpecTypeColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Type',
      field: 'entity.spec.type',
      hidden: true,
      width: 'auto',
    };
  },
  createSpecLifecycleColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Lifecycle',
      field: 'entity.spec.lifecycle',
      width: 'auto',
    }
  },
  createMetadataDescriptionColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Description',
      field: 'entity.metadata.description',
      render: ({ entity }) => (
        <OverflowTooltip
          text={entity.metadata.description}
          placement="bottom-start"
        />
      ),
      width: 'auto',
    };
  },
  createTagsColumn(filter?: string): TableColumn<CatalogTableRow> {
    return {
      ...(filter === 'domain' && {defaultSort: 'desc'}),
      title: 'Tags',
      field: 'entity.metadata.tags',
      cellStyle: {
        padding: '0px 16px 0px 20px',
      },
      render: ({ entity }) => (
        <>
          {entity.metadata.tags?.map(t => (
              <Chip
                key={t}
                label={t}
                size="small"
                variant="outlined"
                style={{ marginBottom: '0px' }}
              />
            ))}
        </>
      ),
      width: 'auto',
    };
  },
  createTitleColumn(options?: {
    hidden?: boolean;
  }): TableColumn<CatalogTableRow> {
    return {
      title: 'Title',
      field: 'entity.metadata.title',
      hidden: options?.hidden,
      searchable: true,
    };
  },
  createLabelColumn(
    key: string,
    options?: { title?: string; defaultValue?: string },
  ): TableColumn<CatalogTableRow> {
    return {
      title: options?.title || 'Label',
      field: 'entity.metadata.labels',
      cellStyle: {
        padding: '0px 16px 0px 20px',
      },
      render: ({ entity }: { entity: Entity }) => {
        const labels: Record<string, string> | undefined =
          entity.metadata?.labels;
        const specifiedLabelValue =
          (labels && labels[key]) || options?.defaultValue;
        return (
          <>
            {specifiedLabelValue && (
              <Chip
                key={specifiedLabelValue}
                label={specifiedLabelValue}
                size="small"
                variant="outlined"
              />
            )}
          </>
        );
      },
      width: 'auto',
    };
  },
  createNamespaceColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Namespace',
      field: 'entity.metadata.namespace',
      width: 'auto',
    };
  },
});
