import {
  Content,
  ContentHeader,
  CreateButton,
  PageWithHeader,
  SupportButton,
} from '@backstage/core-components';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityProcessingStatusPicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
  EntityNamespacePicker,
} from '@backstage/plugin-catalog-react';
import React, { ReactNode, useState } from 'react';
import {
  catalogPlugin,
  DefaultCatalogPageProps,
} from '@backstage/plugin-catalog';
import { CustomCatalogTable } from './CatalogTable/CatalogTable';
import PersonalInfo from './UserList/UserListPicker';
import useFeatureFlags from '../hooks/useFeatureFlags';
import { featureFlagsObj } from '../hooks/config';
import {CustomEntityKindPicker} from './CustomEntityPicker';

export interface BaseCatalogPageProps {
  filters: ReactNode;
  content?: ReactNode;
}

const allowedEntityKindsList: string[] = [
  'component',
  'product',
  'api',
  'resources',
  'group',
  'template',
  'domain',
  'resource',
  'location',
  'user',
];

export function BaseCatalogPage(props: BaseCatalogPageProps) {
  const { filters, content = <CustomCatalogTable /> } = props;
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';
  const createComponentLink = useRouteRef(
    catalogPlugin.externalRoutes.createComponent,
  );
  return (
    <PageWithHeader title={`${orgName} Catalog`} themeId="home">
      <Content>
        <ContentHeader title="">
          <CreateButton
            title="Create"
            to={createComponentLink && createComponentLink()}
          />
          <SupportButton>All your software catalog entities</SupportButton>
        </ContentHeader>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>{filters}</CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>{content}</CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}

export function CustomCatalogIndexPage(props: DefaultCatalogPageProps) {
  const { isOn } = useFeatureFlags({
    id: featureFlagsObj['multiple-owners'],
  });
  const [isService, setIsService] = useState(false);
  const [selected, setSelected] = useState('');
  const handleSelected = (id: string) => {
    setSelected(id);
  };
  const handleService = (response: boolean): boolean => {
    setIsService(response);
    return true;
  };
  const {
    actions,
    initiallySelectedFilter = 'all',
    initialKind = 'domain',
    emptyContent,
    ownerPickerMode,
  } = props;

  return (
    <BaseCatalogPage
      filters={
        <>
          <CustomEntityKindPicker
           allowedKinds={allowedEntityKindsList}
           initialFilter={initialKind}
          />

          
          <EntityTypePicker />

          {isService && isOn ? (
            <PersonalInfo selectedItem={handleSelected} />
          ) : (
            <UserListPicker initialFilter={initiallySelectedFilter} />
          )}

          <EntityOwnerPicker mode={ownerPickerMode} />
          <EntityLifecyclePicker />
          <EntityTagPicker />
          <EntityProcessingStatusPicker />
          <EntityNamespacePicker />
        </>
      }
      content={
        <CustomCatalogTable
          isOwned={selected === 'owned'}
          actions={actions}
          isAll={selected === 'all'}
          emptyContent={emptyContent}
          setSelected={handleSelected}
          handleIsService={handleService}
        />
      }
    />
  );
}
