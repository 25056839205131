import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  container: {
    marginLeft: 12,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));


const GuardRailTags: FC<{
  filterList: string[];
  selected: string;
  onFilter: (tag: string) => void;
}> = ({ filterList, onFilter, selected }) => {
  const classes = useStyles();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    if (event.target.checked) {
      onFilter(event.target.value);
    } else {
      onFilter('');
    }
  };
  return (
    <Accordion defaultExpanded>
    <AccordionSummary>
      <Typography>Tags</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <List component="nav">
        {filterList.map((type:string) => (
          <ListItem key={type} className={classes.listItem} button>
            <ListItemIcon>
              <Checkbox
                value={type}
                edge="start"
                onClick={handleToggle as any}
                checked={selected === type}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={type.charAt(0).toUpperCase() + type.slice(1)}
            />
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  </Accordion>
  )
}

export default GuardRailTags
