import { ANNOTATION_EDIT_URL, ANNOTATION_VIEW_URL, Entity, EntityRelation, RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import { CodeSnippet, Table, TableColumn, TableProps, WarningPanel } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { getEntityRelations, humanizeEntityRef, useEntityList, useStarredEntities } from '@backstage/plugin-catalog-react';
import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import _debounce from 'lodash.debounce';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { featureFlagsObj } from '../../hooks/config';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { getAllSystems, getSystemsByGroupName } from '../../services/systems.service';
import { columnFactories } from './columns';
import { CatalogTableRow } from './types';

/**
 * Props for {@link CatalogTable}.
 *
 * @public
 */
export interface CatalogTableProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  subtitle?: string;
  isOwned?: boolean;
  isAll?: boolean;
  setSelected?: (response: string) => void;
  handleIsService?: (response: boolean) => boolean;
}

interface EntityRelationTarget {
  kind: string;
  name: string;
  namespace: string;
}
export interface CustomEntityRelation extends EntityRelation {
  target?: EntityRelationTarget;
}

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

const refCompare = (a: Entity, b: Entity) => {
  const toRef = (entity: Entity) =>
    entity?.metadata?.title ||
    humanizeEntityRef(entity, {
      defaultKind: 'Component',
    });

  return toRef(a).localeCompare(toRef(b));
};

export async function getSystemByByName(id: string) {
  const res: any[] = [];
  const systemArr = await getSystemsByGroupName(id);
  if (systemArr && systemArr.length > 0)
    for (const data of systemArr) {
      const r = data ? JSON.parse(JSON.stringify(data)).processed_entity : null;

      const response = {
        apiVersion: r ? JSON.parse(r).apiVersion : null,
        kind: r ? JSON.parse(r).kind : null,
        spec: r ? JSON.parse(r).spec : null,
        metadata: r ? JSON.parse(r).metadata : null,
        // Add other properties as needed
      };
      res.push(response);
    }
  return res;
}

export const CustomCatalogTable = (props: CatalogTableProps) => {
  const isService_ = window?.location?.search
    ?.split('kind%5D=')[1]
    ?.includes('system');
  const { columns, actions, tableOptions, subtitle, emptyContent } = props;
  props.handleIsService?.(isService_!!);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { loading, error, entities, filters } = useEntityList();
  
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [ownedSystemArr, setOwnedSystemArr] = useState<any[]>([]);
  const navigate = useNavigate();
  const identityApi = useApi(identityApiRef) as any;
  const customSearchEntities = ['user', 'group'];
  const { isOn } = useFeatureFlags({
    id: featureFlagsObj['multiple-owners'],
  });


  
  useEffect(() => {
    const fetchData = async () => {
      if (isOn) {
        if (
          filters.user?.value === 'owned' &&
          filters.kind?.value === 'system' &&
          !props.isOwned &&
          !props.isAll
        ) {
          const group =
            identityApi?.target?.identity?.ownershipEntityRefs[1]?.split('/')[1];
          const systemArr = await getSystemByByName(group);
          entities.length = 0;
          setOwnedSystemArr(systemArr);
        } else if (props.isOwned && filters.kind?.value === 'system') {
          const group =
            identityApi?.target?.identity?.ownershipEntityRefs[1]?.split('/')[1];
          const systemArr = await getSystemByByName(group);
          entities.length = 0;
          setOwnedSystemArr(systemArr);
        } else if (filters.owners && filters.kind?.value === 'system') {
          const group = filters.owners.values[0]?.split('/')[1];
          const systemArr = await getSystemByByName(group);
          entities.length = 0;
          setOwnedSystemArr(systemArr);
        } else if (props.isAll) {
          const res: any[] = [];
          const systemArr = await getAllSystems();
          for (const data of systemArr) {
            const r = JSON.parse(JSON.stringify(data))?.processed_entity;
            const response = {
              apiVersion: JSON.parse(r)?.apiVersion,
              kind: JSON.parse(r)?.kind,
              spec: JSON.parse(r)?.spec,
              metadata: JSON.parse(r)?.metadata,
            };
            res.push(response);
          }
          entities.length = 0;
          setOwnedSystemArr(res);
        }
      }
    };
    fetchData();
  }, [
    filters.kind,
    filters.user,
    filters.owners,
    props.isOwned,
    identityApi,
    entities,
    isOn,
    props.isAll,
  ]);

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [
      columnFactories.createTitleColumn({ hidden: true }),
      columnFactories.createNameColumn({ defaultKind: filters.kind?.value }),
      ...createEntitySpecificColumns(),
      columnFactories.createMetadataDescriptionColumn(),
      columnFactories.createTagsColumn(filters.kind?.value),
    ];

    function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
      const baseColumns = [
        columnFactories.createSystemColumn(),
        columnFactories.createOwnerColumn(),
        columnFactories.createSpecTypeColumn(),
        columnFactories.createSpecLifecycleColumn(),
      ];
      switch (filters.kind?.value) {
        case 'user':
          return [];
        case 'domain':
        case 'system':
          return [columnFactories.createOwnerColumn()];
        case 'group':
        case 'template':
          return [columnFactories.createSpecTypeColumn()];
        case 'location':
          return [
            columnFactories.createSpecTypeColumn(),
            columnFactories.createSpecTargetsColumn(),
          ];
        default:
          return entities.every(
            entity => entity.metadata.namespace === 'default',
          )
            ? baseColumns
            : [...baseColumns, columnFactories.createNamespaceColumn()];
      }
    }
  }, [filters.kind, entities]);

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  const defaultActions: TableProps<CatalogTableRow>['actions'] = [
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const title = 'View';

      return {
        icon: () => (
          <>
            <Typography variant="srOnly">{title ?? ''}</Typography>
            <OpenInNew fontSize="small" />
          </>
        ),
        tooltip: title ?? '',
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
      const title = 'Edit';

      return {
        icon: () => (
          <>
            <Typography variant="srOnly">{title ?? ''}</Typography>
            <Edit fontSize="small" />
          </>
        ),
        tooltip: title ?? '',
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      const title = isStarred ? 'Remove from favorites' : 'Add to favorites1';

      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => (
          <>
            <Typography variant="srOnly">{title ?? ''}</Typography>
            {isStarred ? <YellowStar /> : <StarBorder />}
          </>
        ),
        tooltip: title ?? '',
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  const rows =  entities
    .concat(ownedSystemArr)
    .sort(refCompare)
    .filter(x=>!x.metadata.name.endsWith('_contributors')).map(entity => {
      const partOfSystemRelations = getEntityRelations(
        entity,
        RELATION_PART_OF,
        {
          kind: 'system',
        },
      );
      const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

      return {
        entity,
        resolved: {
          // This name is here for backwards compatibility mostly; the
          // presentation of refs in the table should in general be handled with
          // EntityRefLink / EntityName components
          name: humanizeEntityRef(entity, {
            defaultKind: 'Component',
          }),
          entityRef: '',

          ownedByRelations,
          partOfSystemRelationTitle: partOfSystemRelations
            .map(r =>
              humanizeEntityRef(r, {
                defaultKind: 'system',
              }),
            )
            .join(', '),
          partOfSystemRelations,
        },
      };
    });

  const showPagination = rows.length > 20;

  const handleTableRowClick = (event: any) => {
    const extractedName = event?.target?.textContent;
    let navigateUrl;
    switch (filters.kind?.value) {
      case 'group': {
        navigateUrl = rows.find(
          x => (x.entity.spec?.profile as any)?.displayName === extractedName,
        );
        const naviUrl = navigateUrl?.entity?.metadata?.name;
        let navUrl = `/catalog/default/group/${naviUrl}`;
        const domainOwner = navigateUrl?.entity?.relations?.find(
          (v: CustomEntityRelation) => v?.target?.kind === 'domain',
        );

        if (domainOwner) {
          navUrl = `/catalog/default/domain/${
            (domainOwner as CustomEntityRelation).target?.name
          }`;
        }

        return navigate(navUrl, {
          state: {
            name: extractedName,
          },
        });
      }

      default:
        break;
    }

    return null;
  };

  /* eslint-disable */
  const executeSearch = (query: string) => {
    const isSearchByGroup = filters.kind?.value === 'group';
    const filteredData = rows.filter(x => {
      if (isSearchByGroup) {
        const displayName = (
          x.entity.spec?.profile as any
        )?.displayName.toLowerCase();
        const description = x.entity.metadata.description?.toLowerCase();
        return (
          displayName?.includes(query.toLowerCase()) ||
          description?.includes(query.toLowerCase())
        );
      }
      return x.entity.metadata.name.includes(query);
    });
    setSearchResults(filteredData);
    setIsSearchLoading(false);
  };

  const debouncedSearch = _debounce(executeSearch, 500);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchParam(value);
    if (!value) {
      setSearchParam(''); // Assuming setSearchParam is a state setter function
    } else {
      debouncedSearch(value);
    }
  };

  // Implementation for multiple system owners
  if (
    filters.kind?.value === 'system' ||
    filters.kind?.value === 'component' ||
    filters.kind?.value === 'resource' ||
    filters.kind?.value === 'api'
  ) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]?.resolved?.ownedByRelations.length < 1) {
        const { owner } = rows[i]?.entity?.spec as any;
        const splitOwner = owner?.split(' ');
        splitOwner.map((item: string) => {
          rows[i]?.resolved?.ownedByRelations?.push({
            kind: 'group',
            namespace: 'default',
            name: item,
          });
        });
      }
      if (rows[i]?.resolved?.ownedByRelations[0]?.name?.includes(' ')) {
        rows[i]?.resolved?.ownedByRelations[0]?.name?.split(' ').map(r => {
          rows[i]?.resolved?.ownedByRelations?.push({
            kind: 'group',
            namespace: 'default',
            name: r,
          });
        });
        rows[i]?.resolved?.ownedByRelations.shift();
      }
    }
  }

  return (
    <div>
      <section>
        {customSearchEntities.includes(filters.kind?.value as string) && (
          <TextField
            id="standard-basic"
            label="Filter"
            variant="standard"
            type="search"
            onChange={handleChange}
            style={{
              top: '2%',
              float: 'right',
              position: 'absolute',
              zIndex: 1,
              right: '36px',
            }}
          />
        )}
      </section>
      <Table
        onRowClick={handleTableRowClick}
        isLoading={loading || isSearchLoading}
        columns={columns || defaultColumns}
        options={{
          paging: showPagination,
          pageSize: 20,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          search: !customSearchEntities.includes(filters.kind?.value as string),
          showEmptyDataSourceMessage: false,
          padding: 'dense',
          pageSizeOptions: [20, 50, 100],
          ...tableOptions,
        }}
        initialState={{
          filtersOpen: true,
        }}
        title={`All ${filters.kind?.value === 'system' ? 'product' : filters.kind?.value}s (${
          filters.kind?.value === 'system' ? rows.length : entities.filter(x=>!x.metadata.name.endsWith('_contributors')).length
        })`}
        data={searchParam ? searchResults : rows}
        initialFormData={{
          FormData,
        }}
        actions={actions || defaultActions}
        subtitle={subtitle}
        emptyContent={emptyContent}
      />
    </div>
  );
};

CustomCatalogTable.columns = columnFactories;
