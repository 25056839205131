import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css'; // Assuming you have CSS for modal styling
import { Button } from '@material-ui/core';

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;  // Optional close function
}

const Modal = ({ children, onClose }: ModalProps) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()} // Prevent close on clicking inside modal
      >
        {children}
        <div className={styles.modalActions}>
          <Button variant='contained' color='default' onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>,
    document.getElementById('root-modal') as HTMLElement
  );
};

export default Modal;
