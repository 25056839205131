import { Entity } from "@backstage/catalog-model";
import { InfoCard } from "@backstage/core-components";
import { useEntity } from "@backstage/plugin-catalog-react";
import { ImageList, makeStyles } from "@material-ui/core";
import Edit from '@material-ui/icons/Edit';
import LanguageIcon from '@material-ui/icons/Language';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'

import { groupsDocumentation } from "../../utils";

interface linkCardProps {
  groupInfoEntity?: Entity;
}

interface linkProps{
  title:string;
  url:string;
}
const useStyles = makeStyles({
  link: {
    color: '#05164d',
    cursor: 'pointer',
    display: 'flex',
    marginTop: '10px',
    '&:hover': {
      textDecoration: 'underline',
    },
  
  },
  container:{
    position:'relative'
  },
  button: {
      height: '34px',
      lineHeight: '10px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'darkblue',
      float:'right',
      marginTop:'-70px',
      '& hover':{
        color:'red'
      }
    }
}
);

const CustomLinks=({groupInfoEntity}:linkCardProps)=>{
const isGroup = window.location.pathname.split('/')[3] === 'group';

  const {entity}=useEntity();
  const classes = useStyles();
  const [links,setLinks]=useState<linkProps[]>([]);
  const yamlLink =entity?.metadata?.annotations!!['backstage.io/managed-by-origin-location'].replace('url:','');
  useEffect(()=>{
    let linkList:linkProps[]=[];
    if(isGroup){
      linkList=groupInfoEntity?.metadata?.links as linkProps[]
    }
    else{
      linkList= entity.metadata?.links as linkProps[]
    }
    
      setLinks(linkList)
  },[groupInfoEntity,entity.metadata?.links,isGroup])

 

  const handleOnClick = () => {
    return window.open(yamlLink??groupsDocumentation, '_blank');
  };


  
  return (
   <InfoCard title="Links">
        <button className={classes.button} onClick={handleOnClick}><Edit/></button>
    {links && links.length > 0 && (
      <ImageList rowHeight="auto" style={{flexWrap:'nowrap'}}>
      {links.map(( item) => (
          <div className={classes.link} key={item.title}>
         <LanguageIcon />
          <Link to={item.url} >
          {item.title}
          </Link>
          </div>
       
      ))}
    </ImageList>
    )}
  </InfoCard>
  )
}

export default CustomLinks;