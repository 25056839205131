import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';

export const getAdrListByUrl = async (url: string) => {
  return fetchData(`${urls.getMultiAdrList}${url}`);
};

export const getAdrContentBytitle = async (title: string) => {
  return fetchData(`${urls.getAdrContentByName}${title}`);
};

export const getAllAdrs = async () => {
  return fetchData(`${urls.getAllAdrs}`);
};
