import { Entity, RELATION_OWNED_BY, parseEntityRef } from '@backstage/catalog-model';
import {
  Content,
  Header,
  HeaderLabel,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from '@backstage/core-components';
import {
  attachComponentData,
  useApi,
  useElementFilter,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityRouteRef,
  getEntityRelations,
  InspectEntityDialog,
  UnregisterEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EntityContextMenu } from './EntityContextMenu';
import {
  EntityLayoutProps,
  EntityLayoutRouteProps,
  EntityLayoutTitle,
  headerProps,
} from './index';

const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); 

function EntityLabels(props: { entity: Entity }) {
  const { entity } = props;
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const hasRelations = ownedByRelations.length;


  const [names, setNames] = useState<JSX.Element>();
  const catalogApi = useApi(catalogApiRef);


   const fetchNames=useCallback(async(ids:string)=>{
    const split = ids?.split(' ');
    const response: JSX.Element[] = [];
    if(split && split.length>0){
      for (const item of split) {
        try {
          const groupItem = { kind: 'group', name: item };
          const entityRef = await parseEntityRef(groupItem);
          const value = await catalogApi.getEntityByRef(entityRef) as any;
    
          if (value) {
            const profileName = <Link style={{color:'white'}} to={`/catalog/default/group/${value?.metadata?.name}`}>{`${value.spec?.profile?.displayName}  `}</Link> 
            response.push(profileName);
          }
        } catch (error) {
          if(error instanceof Error){
            return error.message
          }
        }
      }
    }
    
    return response;
  },[catalogApi])
  
   const fetchData=useCallback(async()=> {
    const fetchedNames = await fetchNames(ownedByRelations[0]?.name) as any;
    setNames(fetchedNames);
   },[ownedByRelations,fetchNames])

  useEffect(() => {
    if(!names){
      fetchData();

    }
  }, [fetchData,names]); 

  
 
  return (
    <>
      {!!hasRelations && (
        <HeaderLabel
          label="Owner"
          contentTypograpyRootComponent="p"
          value={
           names
          }
        />
      )}
      {entity.spec?.lifecycle && (
        <HeaderLabel
          label="Lifecycle"
          value={entity.spec.lifecycle?.toString()}
        />
      )}
    </>
  );
}

export const CustomDomainEntityLayout = (props: EntityLayoutProps) => {
  const {
    UNSTABLE_extraContextMenuItems,
    UNSTABLE_contextMenuOptions,
    children,
    NotFoundComponent,
  } = props;

  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const location = useLocation();


  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<EntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity || (elementProps.if && !elementProps.if(entity))) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  const { headerTitle, headerType } = headerProps(
    kind,
    namespace,
    name,
    entity,
  );

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  };

  useEffect(() => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  }, [location.pathname]);

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <Header
        title={<EntityLayoutTitle title="" entity={entity!} />}
        pageTitleOverride={headerTitle}
        type={headerType}
      >
        {entity && (
          <>
            <EntityLabels entity={entity} />
            <EntityContextMenu
              UNSTABLE_extraContextMenuItems={UNSTABLE_extraContextMenuItems}
              UNSTABLE_contextMenuOptions={UNSTABLE_contextMenuOptions}
              onUnregisterEntity={() => setConfirmationDialogOpen(true)}
              onInspectEntity={() => setInspectionDialogOpen(true)}
            />
          </>
        )}
      </Header>

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent || (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

CustomDomainEntityLayout.Route = Route;
