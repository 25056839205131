import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isOrphan, 
} from '@backstage/plugin-catalog';
import { Entity } from '@backstage/catalog-model';
import { ApiHolder } from '@backstage/core-plugin-api';

export const entityWarningContent = (
  hasCatalogProcessingErrors: 
    (
      entity: Entity, 
      context: {
        apis: ApiHolder;
      }
    ) 
  => Promise<boolean>) =>
 (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);
