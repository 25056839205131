import { isAzurePipelinesAvailable } from '@backstage/plugin-azure-devops';
import { EntityLayout, EntitySwitch, hasCatalogProcessingErrors, isKind } from '@backstage/plugin-catalog';
import React from 'react';

import { CustomDomainEntityLayout } from '../../pages/EntityLayout/CustomDomainEntityLayout';
import PipelineHistory from '../../pages/PipelineHistory';
import { pipelinesContent } from './content/entityPipelinesContent';
import { entityWarningContent } from './content/entityWarningContent';
import { overviewContent } from './content/overviewContent';
import { techdocsContent } from './content/techdocsContent';
import { apiPage } from './pages/ApiPage';
import { componentPage } from './pages/ComponentPage';
import { DomainPage } from './pages/DomainPage';
import { groupPage } from './pages/GroupPage';
import { locationPage } from './pages/LocationPage';
import { systemPage } from './pages/SystemPage';
import { userPage } from './pages/UserPage';
import { templatePage } from './pages/TemplatePage';

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

export const defaultEntityPage = () => (
  <CustomDomainEntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzurePipelinesAvailable}
      path="/pipelines"
      title="Pipelines"
    >
      {pipelinesContent(isAzurePipelinesAvailable)}
    </EntityLayout.Route>
    <EntityLayout.Route path="/pipelinesHistory" title="Pipeline History">
      <PipelineHistory />
    </EntityLayout.Route>
  </CustomDomainEntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')}>
      {componentPage(overviewContent)}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>
      {apiPage(entityWarningContent(hasCatalogProcessingErrors))}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('group')}>
      {groupPage(entityWarningContent(hasCatalogProcessingErrors))}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('user')}>
      {userPage(entityWarningContent(hasCatalogProcessingErrors))}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('system')}>
      {systemPage(entityWarningContent(hasCatalogProcessingErrors))}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('location')}>
      {locationPage(entityWarningContent(hasCatalogProcessingErrors))}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('domain')}>
      <DomainPage
        entityWarningContent={entityWarningContent(hasCatalogProcessingErrors)}
      />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('template')}>
      {templatePage()}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{defaultEntityPage()}</EntitySwitch.Case>
  </EntitySwitch>
);
