import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';

export const getAllDevSecOpsEpics = async () => {
  return fetchData(`${urls.getDevSecOpsEpics}`);
};

export const getEpicAvatarArr = async (data: string) => {
  return fetchData(`${urls.getEpicAvatarArr}${data}`);
};
