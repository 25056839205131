import { parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useMemo, useState } from 'react';

import { getPipelineDataByTemplateName } from '../services/utils.service';

interface PipelineProps {
  spec: {
    adoProjectName: string;
    componentName: string;
    owner: string;
  };
  createdAt: string;
  createdBy: string;
  team: string[];
}

const PipelineHistory = () => {
  const [PipelineHistoryData, setPipelineHistoryData] = useState<
    PipelineProps[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupNames, setGroupNames] = useState<any[]>([]);
  const catalogApi = useApi(catalogApiRef);

  const getPipelineData = useMemo(() => {
    return async (id: string) => {
      try {
        const response = await getPipelineDataByTemplateName(id);
        setIsLoading(false);
        return response as PipelineProps[];
      } catch (error) {
        setIsLoading(false);
      }
      return [];
    };
  }, []);
  useEffect(() => {
    const url = window.location.pathname.split('/')[4];
    getPipelineData(url).then(response => {
      setPipelineHistoryData(response);
    });
  }, [getPipelineData]);

  useEffect(() => {
    async function getNameById(id: string[]): Promise<string> {
      const data = await Promise.all(
        id.map(async item => {
          const groupItem = {
            kind: 'group',
            namespace: 'default',
            name: item.replaceAll('group:default/', ''),
          };
          const entityRef = await parseEntityRef(groupItem);
          if (entityRef) {
            const value = (await catalogApi.getEntityByRef(entityRef)) as any;
            if (value?.spec) return value?.spec?.profile?.displayName as string;
          }
          return null;
        }),
      );

      return data.join(',');
    }

    PipelineHistoryData.map(async (item, id_: number) => {
      const response = await getNameById(item.team);
      if (response) {
        setGroupNames(groupNames_ => [
          ...groupNames_,
          { id: id_, data: response },
        ]);
      }
    });
  }, [PipelineHistoryData, catalogApi]);

  return (
    <Card data-testid="entity-has-pipelineHistory-card">
      {isLoading && <LinearProgress />}
      {PipelineHistoryData.length > 0 && !isLoading && (
        <CardContent>
          <Table aria-label="group list" size="small">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Project Repository</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Date Triggered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PipelineHistoryData.length > 0 &&
                PipelineHistoryData?.sort((a, b) => {
                  return b.createdAt.localeCompare(a.createdAt);
                }).map((row: PipelineProps, i) => {
                  return (
                    <TableRow key={row?.createdAt + i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{row.spec.adoProjectName}</TableCell>
                      <TableCell>{row.spec.componentName}</TableCell>
                      <TableCell style={{ width: '40rem' }}>
                        {groupNames.length > 0 &&
                          groupNames?.find(x => x.id === i)?.data}
                      </TableCell>
                      <TableCell>{row.createdBy.replaceAll(',', '')}</TableCell>
                      <TableCell>{row.createdAt.split('T')[0]}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CardContent>
      )}
      {PipelineHistoryData.length < 1 && !isLoading && <div>No Data Found</div>}
    </Card>
  );
};

export default PipelineHistory;
