import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';

type Timeline = {
  moved: number;
  ringId: 'adopt' | 'trial' | 'assess' | 'hold';
  date: string;
  description: string;
};

type Link = {
  url: string;
  title: string;
};

export type JsonEntry = {
  timeline: Timeline[];
  links: Link[];
  key: string;
  id: string;
  title: string;
  quadrant: 'languages' | 'infrastructure' | 'process' | 'tools';
};

export class TechRadarClient implements TechRadarApi {
  private async fetchTechRadarEntries(): Promise<RadarEntry[]> {
    try {
      const location = (await fetchData(
        `${urls.getConfigByName}techRadar.location`,
      )) as string;

      if (!location) {
        return [];
      }

      const allFilesInLocation = await fetchData(
        `${urls.getAdrList}${location}`,
      );

      if (!allFilesInLocation?.data) {
        return [];
      }

      const entriesPromises = allFilesInLocation.data.map(async (data: any) => {
        const url = `${location}/${data?.path}`;
        const content = await fetchData(`${urls.getAdrContentByName}${url}`);
        return JSON.parse(content?.data) as RadarEntry;
      });

      return await Promise.all(entriesPromises);
    } catch (error) {
      return [];
    }
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const rings: RadarRing[] = [
      { id: 'adopt', name: 'ADOPT', color: '#5BA300' },
      { id: 'trial', name: 'TRIAL', color: '#009EB0' },
      { id: 'assess', name: 'ASSESS', color: '#C7BA00' },
      { id: 'hold', name: 'HOLD', color: '#E09B96' },
    ];

    const quadrants: RadarQuadrant[] = [
      { id: 'process', name: 'Process & Patterns' },
      { id: 'infrastructure', name: 'Infrastructure' },
      { id: 'languages', name: 'Languages & Frameworks' },
      { id: 'tools', name: 'Tools' },
    ];

    const apiEntries = await this.fetchTechRadarEntries();
    const _entries = apiEntries.map((item: any) => {
      return item.entries;
    });
    const entries = _entries.flat() as RadarEntry[];

    return {
      entries,
      quadrants,
      rings,
    };
  }
}
