import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  rootRouteRef,
  createComponentRouteRef,
  viewTechDocRouteRef,
  createFromTemplateRouteRef,
  unregisterRedirectRouteRef,
} from './routes';
import { entityRouteRef } from '@backstage/plugin-catalog-react';

export const domainPageAboutCardPlugin = createPlugin({
  id: 'domain-page-about-card',
  routes: {
    catalogIndex: rootRouteRef,
    catalogEntity: entityRouteRef,
  },
  externalRoutes: {
    createComponent: createComponentRouteRef,
    viewTechDoc: viewTechDocRouteRef,
    createFromTemplate: createFromTemplateRouteRef,
    unregisterRedirect: unregisterRedirectRouteRef,
  },
});

export const EntityDomainPageAboutCard = domainPageAboutCardPlugin.provide(
  createComponentExtension({
    name: 'Group list card',
    component: {
      lazy: () =>
        import('./components/DomainPageAboutCard/DomainPageAboutCard').then(
          m => m.DomainPageAboutCard,
        ),
    },
  }),
);

export const DomainPageAboutCardPage = domainPageAboutCardPlugin.provide(
  createRoutableExtension({
    name: 'DomainPageAboutCardPage',
    component: () =>
      import('./components/DomainPageAboutCard').then(
        m => m.DomainPageAboutCardPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
