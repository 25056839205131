import { makeStyles } from '@material-ui/core';
import React from 'react';

export interface ADR {
  name: string;
  path?: string;
  title?: string;
  type?: string;
  date?: string;
  status: string;
  initialTreeRelativePath?:string;
  onClick?: (name: string) => void;
  selected: string;
}
const useStyles = makeStyles({
  Adr: {
    // Add your styles here
    // For example:
    padding: '8px',
    marginTop: '10px',
    marginBottom: '20px',
    minWidth: '30px',
    maxWidth: '30rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', // Add shadow on hover
      backgroundColor: '#d3d3d3',
      cursor: 'pointer',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px', // Changed this from 50px to 20px for better UI
  },
});

const Adr = ({ title, status, date, name, path, onClick, selected }: ADR) => {
  const classes = useStyles();

  const handleOnClick = async (id: string) => {
    onClick?.(id);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOnClick(name!!);
    }
  };
  return (
    <div
      className={classes.Adr}
      role="button"
      tabIndex={0}
      onClick={() => handleOnClick(path!! ?? name)}
      onKeyDown={handleKeyDown}
      style={selected === path ? { backgroundColor: '#f2f2f2' } : {}}
    >
      <label>{title?.replace(/\"/g, '')}</label>
      <div className={classes.rowContainer}>
        <label>{date}</label>
        {status && (
          <div
            className={
              status === 'accepted'
                ? 'markDownStatus_accepted'
                : 'markDownStatus_proposed'
            }
          >
            <label>{status.replace(/"/g, '')}</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default Adr;
