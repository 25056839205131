
import { InfoCard } from '@backstage/core-components';

import React from 'react';
import { ComponentsGrid } from './ComponentsGrid';


/** @public */
export const OwnershipCard = () => {
  

  return (
    <InfoCard title="Ownership">
      <ComponentsGrid/>
    </InfoCard>
  );
};