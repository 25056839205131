import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityHasSubcomponentsCard,
  EntityLinksCard,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { entityWarningContent } from './entityWarningContent';

import { EntityDomainPageAboutCard } from '@internal/plugin-domain-page-about-card';
import { LinguistCard } from '../../../pages/Linguist';

export const overviewContent = (
  <Grid
    container
    spacing={3}
    alignItems="stretch"
    data-testid="entity-domain-page-about"
  >
    {entityWarningContent(hasCatalogProcessingErrors)}
    <Grid item md={6}>
      <EntityDomainPageAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={12} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={12} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>

    <Grid item md={12}>
      <LinguistCard />
    </Grid>
  </Grid>
);
