import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the state type
export interface ownersProps {
  owners: any[]; // Assuming owners is an array of strings
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ownersProps = {
  owners: [],
  loading: false,
  error: null,
};
export const fetchOwnersAsync = createAsyncThunk<string[], void>(
  'owners/fetchOwners',
  async () => {
    const response = await fetchData(urls.getAllProcessedOwners);
    return response; // Assuming this returns an array of strings
  },
);


export const ownerShipSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {},
  // Handle async actions in extraReducers
  extraReducers: builder => {
    builder
      // Pending state: when the fetch request is started
      .addCase(fetchOwnersAsync.pending, state => {
        state.loading = true;
        state.error = null;
      })
      // Fulfilled state: when the fetch request is successful
      .addCase(
        fetchOwnersAsync.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.owners = action.payload;
          state.loading = false;
        },
      )
      // Rejected state: when the fetch request fails
      .addCase(fetchOwnersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch owners';
      });
  },
});

// Export the reducer to be added to the store
export default ownerShipSlice.reducer;
