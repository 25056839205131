

import React from 'react';
import { HomePageStarredEntities } from "@backstage/plugin-home";
import { Content, DocsIcon, Header, LinkButton, Page } from "@backstage/core-components";
import { HomePageSearchBar } from "@backstage/plugin-search";
import { Grid, makeStyles } from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/Category";
import { WelcomeTitle } from "./WelcomeTitle";

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

export const HomePage = () => {

    const classes = useStyles();
    
    return (<Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
        <Content>
            <Grid container justifyContent="center" spacing={3} style={{ minHeight: '100vh' }}>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <LinkButton
                        startIcon={<DocsIcon />}
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ margin: '1rem' }}
                        to="/docs/default/System/DH-Dock/tutorials/onboarding/"
                    >
                        Get Onboarded to DH-Dock
                    </LinkButton>
                    <LinkButton
                        startIcon={<CategoryIcon />}
                        size="large"
                        variant="outlined"
                        style={{ margin: '1rem' }}
                        to="/docs/default/System/DH-Dock"
                    >
                        Read the DH-Dock Docs
                    </LinkButton>
                </Grid>
                <Grid item xs={12}>
                    <HomePageSearchBar
                        InputProps={{
                            classes: {
                                root: classes.searchBarInput,
                                notchedOutline: classes.searchBarOutline
                            }
                        }}
                        placeholder="Search"
                    />
                </Grid>
                <Grid item md={6}>
                    <HomePageStarredEntities />
                </Grid>
            </Grid>
        </Content>
    </Page>
    );
};