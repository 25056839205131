import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
// import { EntityDomainPageAboutCard } from '@internal/plugin-domain-page-about-card';
import { Grid } from '@material-ui/core';
import React from 'react';

import { CustomDomainEntityLayout } from '../../../pages/EntityLayout/CustomDomainEntityLayout';

export const locationPage = (entityWarningContent: JSX.Element) => (
  <CustomDomainEntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </CustomDomainEntityLayout>
);
