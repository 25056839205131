import { EntityApiDefinitionCard, EntityConsumingComponentsCard, EntityProvidingComponentsCard } from "@backstage/plugin-api-docs";
import { EntityLayout, EntityLinksCard } from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import { EntityDomainPageAboutCard } from "@internal/plugin-domain-page-about-card";
import { Grid } from "@material-ui/core";
import React from "react";

import { CustomDomainEntityLayout } from "../../../pages/EntityLayout/CustomDomainEntityLayout";

export const apiPage = (entityWarningContent: JSX.Element) => (
    <CustomDomainEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item md={6}>
          <EntityDomainPageAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={4} xs={12}>
            <EntityLinksCard />
          </Grid>
          <Grid container item md={12}>
            <Grid item md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>
  
      <EntityLayout.Route path="/definition" title="Definition">        
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EntityApiDefinitionCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </CustomDomainEntityLayout>
  );