import {
  Entity,
  DEFAULT_NAMESPACE,
  RELATION_OWNED_BY,
  parseEntityRef,
  isGroupEntity,
  isUserEntity,
  isComponentEntity,
} from '@backstage/catalog-model';
import {
  Content,
  Header,
  HeaderLabel,
  Link,
  Page,
  Progress,
  WarningPanel,
} from '@backstage/core-components';
import {
  attachComponentData,
  IconComponent,
  useRouteRefParams,
  useApi,
} from '@backstage/core-plugin-api';

import {
  EntityDisplayName,
  EntityProvider,
  EntityRefLinks,
  entityRouteRef,
  FavoriteEntity,
  getEntityRelations,
  useAsyncEntity,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import {  EntityUserProfileCard } from '@backstage/plugin-org';
import { Box, Grid, TabProps } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MembersList from '../Members';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useAsync } from 'react-use';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { featureFlagsObj } from '../../hooks/config';
import CustomLinks from '../Links';
import { GroupSecurityMetricsCard } from '../GroupSecurityMetrics/GroupSecurityMetricsCard';
import { OwnershipCard } from '../OwnershipCard/OwnershipCard';
/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};
const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

export function EntityLayoutTitle(props: { title: string; entity?: Entity }) {
  const { entity, title } = props;
  return (
    <Box display="inline-flex" alignItems="center" height="1em" maxWidth="100%">
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {entity ? <EntityDisplayName entityRef={entity} hideIcon /> : title}
      </Box>
      {entity && <FavoriteEntity entity={entity} />}
    </Box>
  );
}

export function headerProps(
  paramKind: string | undefined,
  paramNamespace: string | undefined,
  paramName: string | undefined,
  entity: Entity | undefined,
): { headerTitle: string; headerType: string } {
  const kind = paramKind ?? entity?.kind ?? '';
  const namespace = paramNamespace ?? entity?.metadata.namespace ?? '';
  const name =
    entity?.metadata.title ?? paramName ?? entity?.metadata.name ?? '';
  return {
    headerTitle: `${name}${
      namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''
    }`,
    headerType: (() => {
      let t = kind?.toLocaleLowerCase('en-US');
      if (entity?.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec as { type: string })?.type?.toLocaleLowerCase(
          'en-US',
        );
      }
      return t;
    })(),
  };
}

function EntityLabels(props: { entity: Entity }) {
  const { entity } = props;
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  return (
    <>
      {ownedByRelations?.length > 0 && (
        <HeaderLabel
          label="Owner"
          value={
            <EntityRefLinks
              entityRefs={ownedByRelations}
              defaultKind="Group"
              color="inherit"
            />
          }
        />
      )}
      {entity.spec?.lifecycle && (
        <HeaderLabel
          label="Lifecycle"
          value={entity.spec.lifecycle?.toString()}
        />
      )}
    </>
  );
}

interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
}

type VisibleType = 'visible' | 'hidden' | 'disable';

interface EntityContextMenuOptions {
  disableUnregister: boolean | VisibleType;
}

/** @public */
export interface EntityLayoutProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: EntityContextMenuOptions;
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
}

export const CustomEntityLayout = (props: EntityLayoutProps) => {
  const { NotFoundComponent } = props;
  const location = useLocation();
  const locationState = location.state || {};

  const [customTitle, setCustomTitle] = useState<string | null>(null);
  document.title = customTitle!!;
  const defaultEntity = {
    apiVersion: 'v1',
    kind: 'Component',
    metadata: {
      name: customTitle || '',
      description: 'This is the description',
      links: [],
    },
    spec: {
      owner: 'guest',
      type: 'service',
      lifecycle: 'production',
    },
  };
  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const { headerTitle, headerType } = headerProps(
    kind,
    namespace,
    name,
    entity,
  );
  useEffect(() => {
    const splitPath = location.pathname.split('/');
    const path = splitPath[splitPath?.length - 1]
      ?.toString()
      .toString()
      .replace(/%20/g, ' ');
    document.title = locationState?.name || path!!;
    setCustomTitle(path);
  }, [locationState.name, location.pathname]);
  const { isOn } = useFeatureFlags({
    id: featureFlagsObj['multiple-owners'],
  });
  const isGroup = isGroupEntity(entity as Entity);
  const isUser = isUserEntity(entity as Entity);
  const isComponent = isComponentEntity(entity as Entity);
  document.title = locationState?.name || customTitle!!;
  const catalogApi = useApi(catalogApiRef);
  const groupInfoEntityRef = parseEntityRef(
    `groupinfo:${entity?.metadata?.namespace}/${entity?.metadata?.name}`,
  );
  const { value: groupInfoEntity } = useAsync(() =>
    catalogApi.getEntityByRef(groupInfoEntityRef),
  );

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <Header
        title={
          <EntityLayoutTitle
            title={locationState?.name || customTitle!!}
            entity={entity!}
          />
        }
        pageTitleOverride={headerTitle}
        type={headerType}
      >
        {entity && <EntityLabels entity={entity} />}
      </Header>

      {loading && <Progress />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent || (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <Content>
        <Grid container spacing={3} direction="row">
          <Grid item md={12} xs={12}>
            <EntityProvider entity={entity || defaultEntity}>
              {isComponent && (
                <EntityCatalogGraphCard variant="gridItem" height={400} />
              )}

              {groupInfoEntity && isGroup && (
                <CustomLinks groupInfoEntity={groupInfoEntity} />
              )}

                <Grid item xs={12}>
                  <br />
                  <OwnershipCard />
                  <br />
                  <Grid item xs={12}>
                    <EntityProvider entity={entity || defaultEntity}>
                      {!isUser && (
                        <MembersList
                          memberType="core"
                          groupInfoEntity={groupInfoEntity}
                          members={entity?.relations!!}
                          name={locationState?.name || customTitle!!}
                        />
                      )}
                    </EntityProvider>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {!isUser && (
                      <MembersList
                        memberType="contributor"
                        groupInfoEntity={groupInfoEntity}
                        members={entity?.relations!!}
                        name={locationState?.name || customTitle!!}
                      />
                    )}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {isGroup && (
                      <GroupSecurityMetricsCard title="SonarQube Metrics" />
                    )}
                  </Grid>
                
              </Grid>
            </EntityProvider>
          </Grid>

          <Grid item md={12} xs={12}>
            <EntityProvider entity={entity || defaultEntity}>
              {isUser && !isOn && (
                <EntityProvider entity={entity || defaultEntity}>
                  <EntityUserProfileCard />
                </EntityProvider>
              )}
            </EntityProvider>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

CustomEntityLayout.Route = Route;
