import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { EmptyState, InfoCardVariants } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import { sonarQubeApiRef, useProjectInfo } from '@backstage/plugin-sonarqube-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common'
import { Card, CardContent, CardHeader, Grid, LinearProgress, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import BugReport from '@material-ui/icons/BugReport';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import React, { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';

import { Rating } from './Rating';
import { RatingCard } from './RatingCard';
import { Value } from './Value';

type SecurityMetricsCardProps = {
  title: string;
  variant?: InfoCardVariants;
};

type SecurityMetricsListProps = {
  row: Entity | undefined;
};




const useStyles = makeStyles({
  hideRow: {
    display: 'none',
  },
});


function SecurityMetricsList(props: SecurityMetricsListProps) {
  const classes = useStyles();
  const { row: entity } = props;
  const sonarQubeApi = useApi(sonarQubeApiRef);
  const { projectKey: projectTitle, projectInstance } = useProjectInfo(
    entity as Entity,
  );
  const { value, loading } = useAsync(() =>
    sonarQubeApi.getFindingSummary({
      componentKey: projectTitle,
      projectInstance: projectInstance,
    }),
  );

  const tableRowStyle = !value ? classes.hideRow : '';
  return (
    <TableRow className={tableRowStyle}>
      <TableCell>
        <EntityRefLink entityRef={entity as Entity} hideIcon />
      </TableCell>
      <TableCell>{entity?.metadata?.description}</TableCell>
      <TableCell>
        {loading && <div>Load data...</div>}
        {!loading && value && (
          <>
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: '100%' }}
              spacing={0}
            >
              <Grid item container justifyContent="space-around">
                <RatingCard
                  titleIcon={<BugReport />}
                  title="Bugs"
                  link={value.getIssuesUrl('BUG')}
                  leftSlot={<Value value={value.metrics.bugs} />}
                  rightSlot={
                    <Rating rating={value.metrics.reliability_rating} />
                  }
                />

                <RatingCard
                  titleIcon={
                    value.metrics.vulnerabilities === '0' ? (
                      <Lock />
                    ) : (
                      <LockOpen />
                    )
                  }
                  title="Vulnerabilities"
                  link={value.getIssuesUrl('VULNERABILITY')}
                  leftSlot={<Value value={value.metrics.vulnerabilities} />}
                  rightSlot={<Rating rating={value.metrics.security_rating} />}
                />

                <RatingCard
                  titleIcon={
                    value.metrics.code_smells === '0' ? (
                      <SentimentVerySatisfied />
                    ) : (
                      <SentimentVeryDissatisfied />
                    )
                  }
                  title="Code Smells"
                  link={value.getIssuesUrl('CODE_SMELL')}
                  leftSlot={<Value value={value.metrics.code_smells} />}
                  rightSlot={<Rating rating={value.metrics.sqale_rating} />}
                />
              </Grid>
            </Grid>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export function GroupSecurityMetricsCard(props: SecurityMetricsCardProps) {
  const { title } = props;
  const catalogApi = useApi(catalogApiRef) as any;
  const group = window?.location?.pathname?.split('/')[4];
  const [owners, setOwners] = useState<string[]>([]);
  const [entities, setEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string|null>(null);



  const getOwnerShipByTeam = useMemo(async () => {
    try {
      const ownership_ = await fetchData(
        `${urls.getOwnershipCount}${group}`);
      return  ownership_;
    } catch (exception) {
      return Object;
    }
  }, [group]);

  useEffect(() => {
      getOwnerShipByTeam.then(response => {
        const components=response?.filter((x: { kind: string; })=>x?.kind==='component');
          const data=components.map((item:any)=>{
            const groupInfoEntityRef = parseEntityRef(
              item.entity_ref,
            );
            return groupInfoEntityRef
          })
          setOwners(data);
        
      });
   
  }, [getOwnerShipByTeam]);


  useEffect(() => {
    const fetc_hData = async () => {
      try {
        // Create an array of promises to fetch each entity by ref
        const dataPromises = owners.map((item) => catalogApi.getEntityByRef(item));

        // Wait for all promises to resolve
        const data = await Promise.all(dataPromises);
        // Update state with the fetched data
        setEntities(data);
      } catch (err) {
        if(err instanceof Error)
        setError(err.message)
      } finally {
        setLoading(false);
      }
    };

    fetc_hData();
  }, [catalogApi, owners]);
    
  
  if(error){
    <div>An error occured</div>
  }
  

  return (
    <Card>
      {loading && <LinearProgress />}
      <CardHeader title={title} />
      <CardContent>
        {entities?.length ? (
          <Table aria-label="security metrics list" size="small">
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell>SONARQUBE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map(
                (row:any, i:number) => (
                  <SecurityMetricsList key={i} row={row} />
                ),
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyState missing="data" title="No metrics available" />
        )}
      </CardContent>
    </Card>
  );
}
