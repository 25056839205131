import {
    alertApiRef,
    identityApiRef,
    useApi,
} from '@backstage/core-plugin-api';
import { Tooltip, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';

export const getFirstName = (email: string): string => {
    const firstLetter = email.charAt(0).toUpperCase()
    const restOfName = email.split('.')[0].slice(1)
    return firstLetter + restOfName
}
export const WelcomeTitle = () => {
    const identityApi = useApi(identityApiRef);
    const alertApi = useApi(alertApiRef);

    const { value: profile, error } = useAsync(() =>
        identityApi.getProfileInfo(),
    );

    useEffect(() => {
        if (error) {
            alertApi.post({
                message: `Failed to load user identity: ${error}`,
                severity: 'error',
            });
        }
    }, [error, alertApi]);

    return (
        <Tooltip title='Welcome'>
            <Typography component="span" variant="inherit">{`Welcome${
                profile?.email ? ` ${getFirstName(profile.email)}` : ''
            }!`}</Typography>
        </Tooltip>
    );
};