import { Paper, TableContainer } from '@material-ui/core';
import React from 'react';
import { RenderMarkDown } from '../CustomMarkDownReader';
import { AdrContentProps } from './types';

const AdrContent = ({ baseUrl, retrievedContent }: AdrContentProps) => {
  return (
    <>
      <TableContainer component={Paper} style={{ padding: '2rem' }}>
        <div className="markdown-content" id="another-markdown-container">
          <RenderMarkDown retrievedContents={retrievedContent} url={baseUrl} />
        </div>
      </TableContainer>
    </>
  );
};

export default AdrContent;
