import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  OdpExtension,
} from './odpCustomExtension';

export const OdpNameExtension: any = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'odpName',
    component: OdpExtension,
  }),
);
