import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';

export const getSystemsByGroupName = async (url: string) => {
  return fetchData(`${urls.systemsByGroup}${url}`);
};


export const getAllSystems = async () => {
  return fetchData(`${urls.getAllSystems}`);
};

export const getSystemsCountByTeams = async () => {
  return fetchData(`${urls.getSystemsCountByTeam}`);
};
