import React from 'react';

import Adr from './Adr';
import { AdrProps } from './types';

const AdrList = ({
  name,
  selected,
  status,
  date,
  onClick,
  path,
  title,
  initialTreeRelativePath
}: AdrProps) => {
  return (
    <Adr
      key={path}
      status={status}
      name={name}
      title={title}
      date={date}
      selected={selected}
      path={path}
      onClick={() => onClick?.(path!! || name,initialTreeRelativePath)}
    />
  );
};

export default AdrList;
