import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import userAvatar from '../../assets/useravatar.png'

import './jira.css';

export interface JiraCardProps {
  assignee: string;
  assigneeName: string;
  avatarUrl: string;
  id: string;
  key: string;
  priorityId: string;
  projectId: bigint;
  statusId: string;
  summary: string;
  typeId: string;
  done:boolean
}

interface avatarProps{
  user:string;
  picture:string;
  prevName:string;
}
const JiraCard:FC<{data:JiraCardProps,avatarList:avatarProps[]}> = ({data,avatarList}) => {
     const pic=avatarList.find(x=>x?.prevName===data.assigneeName)
    const onClickTicket=(ticketId:string)=>{
        return window.open(`https://trackspace.lhsystems.com/browse/${ticketId}`,'__blank')
    }
    
  return (
    <div className="jiraCard">
      
      <div className='jiraCard__content'>
        <Link to="" onClick={()=>onClickTicket(data.key)}>{data.key}</Link>
        <p>{data.summary}</p>
        <img src={pic?.picture??userAvatar} alt={data.id.toString().concat('User Avatar')}/>

      </div>
    </div>
  );
};

export default JiraCard;
