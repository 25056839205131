import {
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';

// docs: https://backstage.io/docs/getting-started/app-custom-theme/
export const customTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#05164d',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#05164d',
      link: '#565a6e',
    },
    navigation: {
      background: '#05164d',
      indicator: '#05164d',
      color: '#ffffff',
      selectedColor: '#1dd5c7',
      navItem: {
        hoverBackground: '#1dd5c7',
      },
    },
  },
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    documentation: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    tool: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    service: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#05164d'], shape: shapes.wave }),
  },
});
