import { EntityTechdocsContent } from "@backstage/plugin-techdocs";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import React from "react";

export const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
     </TechDocsAddons> 
  </EntityTechdocsContent>
);