import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(() => ({
  accordion: {
    width: '100%', 
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor:'rgba(0, 0, 0, 0.08)',
    },
  },
}));


interface SearchTypeAccordionProps {
  name: string;
  defaultValue: string;
  onSelect:(value:string)=>void;
  types: { value: string; name: string; icon: JSX.Element }[];
}

const SearchTypeAccordion: React.FC<SearchTypeAccordionProps> = ({ name, defaultValue, types,onSelect }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState<string>(defaultValue);

  const handleTypeSelect = (value: string) => {
    setSelectedType(value);
    onSelect?.(value)
    // Implement any logic here based on selected type
  };

  return (
    <Grid item xs={12} md={12}> {/* Adjust Grid item size based on your layout needs */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav">
            {types.map((type) => (
              <ListItem
                key={type.value}
                button
                selected={selectedType === type.value}
                onClick={() => handleTypeSelect(type.value)}
                className={classes.listItem}
              >
                <ListItemIcon>{type.icon}</ListItemIcon>
                <ListItemText primary={type.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SearchTypeAccordion;
