import { CatalogIcon, Content, DocsIcon, Header, Page } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { CATALOG_FILTER_EXISTS, catalogApiRef } from '@backstage/plugin-catalog-react';
import { DefaultResultListItem, SearchBar, SearchFilter, SearchPagination, SearchResult, useSearch } from '@backstage/plugin-search-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { Grid, List, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { getAdrContentBytitle, getAdrListByUrl, getAllAdrs } from '../../services/adr.service';
import AdrList from './AdrSearch/AdrList';
import SearchTypeAccordion from './SearchTypeAccordion';
import VisibilityIcon from '@material-ui/icons/Visibility'

export interface adrProps {
  url:string;
  link:string;
  data?: [
    {
      date: string;
      path: string;
      status: string;
      title: string;
      type: string;
    },
  ];
}



const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));
const getAllAdrsList = async () => {
  const response = await getAllAdrs();
  return response;
};

const SearchPage = () => {
  const classes = useStyles();
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);

  const [adrSearchList, setAdrSearchList] = useState<any[]>([]);
  const [adrSearchListCopy, setAdrSearchListCopy] = useState<any[]>([]);
  const [selected,setSelected]=useState('');
  const hasDataRef = useRef(false);

  useEffect(() => {
    const getAllAdrsData = async () => {
      try {
        const Adrs = await getAllAdrsList() as adrProps[];
      if(Adrs && Adrs.length>0){
        const fetchAllAdrs: adrProps[] = await Promise.all(
          Adrs.filter(x=>x.url)?.map(async (item: any) => {
            try {
              const response = await getAdrListByUrl(item?.url);
              response.url=item;
              return response;
            } catch (error) {
              return null;
            }
          
          }),
        );
      if(fetchAllAdrs.length>0){
          fetchAllAdrs.filter(x=>x?.data).map(async (item_: any) => {
            if (Array.isArray(item_.data)) {
              await Promise.all(
                item_.data.map(async (item: any) => {
                  if (item?.path?.endsWith('.md') || item?.path?.endsWith('.MD')) {
                    try {
                      const response = await getAdrContentBytitle(
                        item_?.url?.url?.concat('/').concat(item?.path)
                      );

                      setAdrSearchList((adrList)=>[...adrList,{  path: item,
                        link:item_?.url?.link,
                        content: response,}])
                      setAdrSearchListCopy((adrList)=>[...adrList,{  path: item,
                          link:item_?.url?.link,
                          content: response,}])
                     
                    } catch (error) {
                     return null
                    }
                   
                  }
                  return null
                }),
                
              );
            }
          })
        }
    }
      } catch (error) {
        if(error instanceof Error){
          return null;
        }
      }

      hasDataRef.current = true;
      return null;
    };

    if (!hasDataRef.current) {
      getAllAdrsData();
    }
  }, []);

  const handleSearch = (value:string) => {
    if(value && value.trim()){
      setAdrSearchList([]);
      const searchResult=[...adrSearchList];
      const response=searchResult.filter(x=>x.content.data.includes(value) || x.path.path.includes(value));
      setAdrSearchList(response)
    }
  else{
    setAdrSearchList(adrSearchListCopy)
  }
  };

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paper className={classes.bar}>
              {selected==='adr' ? (
                <SearchBar onChange={(event)=>handleSearch(event)} />
              ) : (
                <SearchBar />
              )}
            </Paper>
          </Grid>
          <Grid item xs={3} style={{}}>
            <SearchTypeAccordion
            onSelect={setSelected}
              name="Result Type"
              defaultValue="software-catalog"
              
              types={[
                {
                  value: 'all',
                  name: 'All',
                  icon: <VisibilityIcon />,
                },
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                  icon: <CatalogIcon />,
                },
             
                {
                  value: 'adr',
                  name: 'Architecture Decision Records',
                  icon: <DocsIcon />,
                },
              ]}
            />
            <Paper className={classes.filters}>
              {types.includes('techdocs') && (
                <SearchFilter.Select
                  className={classes.filter}
                  label="Entity"
                  name="name"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    return items
                      .map(entity => entity.metadata.name)
                      .sort((a: any, b: any) => a - b);
                  }}
                />
              )}
              <SearchFilter.Select
                className={classes.filter}
                label="Kind"
                name="kind"
                values={['Component', 'Template']}
              />
              <SearchFilter.Checkbox
                className={classes.filter}
                label="Lifecycle"
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            {!window.location.href?.endsWith('adr') && <SearchPagination />}
            <SearchResult>
              {({ results }) => (
                <List>
                  <>
                   {selected!=='adr'&& results.map(({ type, document, highlight, rank }) => {
                      switch (type) {
                        case 'software-catalog':
                          return (
                            <CatalogSearchResultListItem
                              key={document.location}
                              result={document}
                              highlight={highlight}
                              rank={rank}
                            />
                          );
                        case 'techdocs':
                          return (
                            <TechDocsSearchResultListItem
                              key={document.location}
                              result={document}
                              highlight={highlight}
                              rank={rank}
                            />
                          );
                      
                        default:
                          return (
                            <DefaultResultListItem
                              key={document.location}
                              result={document}
                              highlight={highlight}
                              rank={rank}
                            />
                          );
                      }
                    })}
                    {selected ==='adr' && (
                      <AdrList
                        result={adrSearchList}
                        // Not required if you're leveraging the new search results extensions available in v1.11+
                        // https://backstage.io/docs/features/search/how-to-guides#2-using-an-extension-in-your-backstage-app
                      />
                    )}
                  </>
                </List>
              )}
            </SearchResult>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
