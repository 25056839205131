import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Input } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
/*
 This is the actual component that will get rendered in the form
*/
export const OdpExtension = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {

  const user=useApi(identityApiRef) as any;
  const [name,setName]=useState('');
  

  useEffect(()=>{
    setTimeout(()=>{
      const username=user.target.profile.displayName.split(',');
      const user_=username[0].trim().concat('_').concat(username[1].trim());
      setName(
        user_
      )
    },5000)
  },[user.target.profile.displayName])

  if(name){
    onChange(name)
  }
  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !formData}

      >
        Requester Name:   <Input
        onChange={e => onChange(e.target?.value)}
        readOnly
        value={name}
      />
      </FormControl>
    </>
  );
}
  
  
